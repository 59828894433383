import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  // DirectionsService,
  // DirectionsRenderer,
  Circle,
  InfoWindow,
  TrafficLayer,
  Polyline,
} from "@react-google-maps/api";
import SVG from "react-inlinesvg";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { IoIosCopy } from "react-icons/io";
import copy from "clipboard-copy";
import { post } from "../components/api";
import ModalPopup from "../components/ModalPopup";
import { SweetAlert } from "../helpers/helper";
import { MdOutlineDirections, MdOutlineWatchLater } from "react-icons/md";
import VehicleMarker from "./customMarker";
import View from "../assets/emergency_services/view.svg";
import FileShare from "../assets/emergency_services/file-share.svg";
import Zoom from "../assets/emergency_services/zoom.svg";
import TrafficSignal from "../assets/emergency_services/traffic.svg";
import stopIcon from "../assets/vehicles/stoppedHistory.svg";
import fuelPin from "../assets/emergency_services/fuel_station_pin.png";
const libraries = ["places", "geometry", "drawing"];

const mapContainerStyle = {
  width: "100%",
  height: "450px",
};
const LiveMapContainer = ({
  socketData,
  currentVehiclePosition,
  locationData,
  nearbyPlaces,
  fuelMarker,
  emergencyServiceType,
  vehicleId,
  currentVehicleData,
  timeFilter,
  stopsData,
  routeType,
  defaultZoom,
  networkStrength,
  // directionsCallback,
  // directions,
  // directionsResults,
  // directionsServiceKey,
  // setDirectionsServiceKey,
  // setDirections,
  // directionOptions
}) => {
  const [openInfoWindow, setOpenInfoWindow] = useState(false);
  const [stopsInfoWindow, setOpenInfoStopWindow] = useState("");
  const [nearByInfoWindow, setNearByInfoWindow] = useState("");
  const [mapType, setMapType] = useState("roadmap");
  const [zoomLevel, setZoomLevel] = useState(false);
  const [showTraffic, setShowTraffic] = useState(false);
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [selectedDay, setSelectedDay] = useState("0d");
  const [selectedHour, setSelectedHour] = useState("0h");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPosition, setCurrentPosition] = useState(null);
  const [progress, setProgress] = useState([]);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });
  const [mapBounds, setMapBounds] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (routeType === "route-history" && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      stopsData.forEach((stop) => {
        if (stop.coordinates) {
          bounds.extend(
            new window.google.maps.LatLng(
              stop.coordinates[0],
              stop.coordinates[1]
            )
          );
        }
      });
      setMapBounds(bounds); // Set the bounds in state
      mapRef.current.fitBounds(bounds); // Set bounds to map
    }
  }, [routeType, stopsData]);

  useEffect(() => {
    if (routeType === "live") {
      setMapBounds(null);
    }
    if (currentVehiclePosition) {
      if (routeType === "live" && locationData.length <= 0) {
        setCurrentPosition({
          lat: currentVehiclePosition[0],
          lng: currentVehiclePosition[1],
          speed: 0,
        });
      } else {
        setCurrentPosition(currentVehiclePosition);
      }
    }
  }, [routeType, currentVehiclePosition]);

  const moveObject = useCallback(async () => {
    const batchSize = 1;
    if (socketData.length > 0) {
      if (progress.length === 0) {
        setProgress([socketData[0]]);
        setCurrentPosition(socketData[0]);
      } else {
        const nextIndex = progress.length;
        const batch = socketData.slice(nextIndex, nextIndex + batchSize);
        if (batch.length > 0) {
          await interpolateMovement(currentPosition, batch); // Pass the batch to interpolateMovement
          setProgress((prevProgress) => [...prevProgress, ...batch]);
          setCurrentPosition(batch[batch.length - 1]); // Set to the last point of the batch
        }
      }
    }
  }, [progress, socketData, currentPosition]);

  useEffect(() => {
    const interval = setInterval(() => {
      moveObject();
    }, 1000);
    return () => clearInterval(interval);
  }, [moveObject]);

  const interpolateMovement = async (startPos, positions) => {
    const totalDuration = 1000; // Total duration for animating the entire batch
    const stepsPerTransition = 60; // Number of steps per transition between two points
    const stepDuration =
      totalDuration / (positions.length * stepsPerTransition); // Calculate step duration for the entire batch
    for (let i = 0; i < positions.length; i++) {
      const endPos = positions[i];
      const latDiff = (endPos.lat - startPos.lat) / stepsPerTransition;
      const lngDiff = (endPos.lng - startPos.lng) / stepsPerTransition;
      for (let step = 0; step < stepsPerTransition; step++) {
        const newLat = startPos.lat + latDiff * step;
        const newLng = startPos.lng + lngDiff * step;
        setCurrentPosition({ lat: newLat, lng: newLng });
        await sleep(stepDuration);
      }
      // After interpolating to the current position, update startPos for the next iteration
      startPos = endPos;
    }

    // Ensure the final position is set to the last point in the batch
    setCurrentPosition(positions[positions.length - 1]);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // copy link
  const copyToClipboard = () => {
    copy(shareUrl);
    SweetAlert("Link copy successfully.", false);
  };

  const toggleMapType = (type) => {
    if (type == "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      const tempShowTrafic = newMapType === "satellite" ? false : showTraffic;
      setShowTraffic(tempShowTrafic);
      setMapType(newMapType); // Update mapType state
    }
    if (type == "traffic-show") {
      setShowTraffic(!showTraffic);
    }
  };

  const NetworkSvg = ({ value }) => {
    const bars = [
      { x: 0, y: 25, width: 4, height: 10 },
      { x: 7, y: 18, width: 4, height: 17 },
      { x: 14, y: 13, width: 4, height: 22 },
      { x: 21, y: 7, width: 4, height: 28 },
      { x: 28, y: 0, width: 4, height: 35 },
    ];
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {bars.map((bar, index) => (
          <rect
            key={index}
            x={bar.x}
            y={bar.y}
            width={bar.width}
            height={bar.height}
            fill={index < value ? "#4CAF50" : "#DBDBDB"}
          />
        ))}
      </svg>
    );
  };

  // close modal
  const handleCloseModal = () => {
    setShowModalPopup(false);
  };

  const handleShowModal = async () => {
    setShowModalPopup(true);
  };

  const handleMarkerClick = () => {
    setOpenInfoWindow(!openInfoWindow);
    setOpenInfoStopWindow("");
  };

  // stop marker click
  const handleStopsMarkerClick = (index) => {
    setOpenInfoStopWindow(index);
    setOpenInfoWindow(false);
  };

  // Nearby marker click
  const handleNearByMarkerClick = (index) => {
    setNearByInfoWindow(index);
    setOpenInfoWindow(false);
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ["roadmap", "satellite"],
      style: window.google.maps.TrafficStyle,
    },
  };

  const handleCreateShareLink = async () => {
    if (selectedDay === "0d" && selectedHour === "0h") {
      setErrorMessage("Please select at least 1 hour to create the link.");
    } else {
      try {
        setLoading(true);
        let jsonData = {
          vehicle_id: vehicleId,
        };
        if (selectedDay !== "0d") {
          jsonData.timeframe = selectedDay;
        }
        if (selectedHour !== "0h") {
          jsonData.timeframe = selectedHour;
        }
        const getToken = await post("/generate-token-web", jsonData);
        if (getToken.data && getToken.data.token !== undefined) {
          setLoading(false);
          setShareUrl(process.env.REACT_APP_SITE_URL + getToken.data.token);
          SweetAlert(getToken.message, false);
        } else {
          setShareUrl("");
          SweetAlert("The link has expired. Please request a new one.", true);
          setLoading(false);
          setShowModalPopup(false);
        }
      } catch (error) {
        SweetAlert(error.message, true);
        setLoading(false);
        setShareUrl("");
        setShowModalPopup(false);
      }
    }
  };

  const handleDirection = () => {
    const location = locationData[locationData.length - 1];
    const { lat, lng } = location;
    const url = `https://www.google.com/maps?q=${lat},${lng}&z=15`;
    window.open(url, "_blank");
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  return (
    <div>
      <div className="google-map-parent">
        {isLoaded && (
          <div className="google-map-parent">
            <GoogleMap
              onLoad={(map) => (mapRef.current = map)}
              mapContainerStyle={mapContainerStyle}
              zoom={zoomLevel ? 14 : defaultZoom}
              // center={{
              //   lat: timeFilter == 'today' ? currentPosition?.lat : locationData[locationData.length - 1].lat,
              //   lng: timeFilter == 'today' ? currentPosition?.lng : locationData[locationData.length - 1].lng
              // }}
              center={
                mapBounds // Use stored bounds for center
                  ? {
                      lat: mapBounds.getCenter().lat(),
                      lng: mapBounds.getCenter().lng(),
                    }
                  : {
                      lat:
                        timeFilter === "today"
                          ? currentPosition?.lat
                          : locationData[locationData.length - 1].lat,
                      lng:
                        timeFilter === "today"
                          ? currentPosition?.lng
                          : locationData[locationData.length - 1].lng,
                    }
              }
              options={mapOptions}
            >
              {currentPosition && (
                <VehicleMarker
                  currentVehicleData={currentVehicleData}
                  currentPosition={currentPosition}
                  locationData={locationData}
                  timeFilter={timeFilter}
                  handleMarkerClick={handleMarkerClick}
                  openInfoWindow={openInfoWindow}
                />
              )}
              {routeType == "route-history" &&
                stopsData.length > 0 &&
                stopsData.map((stop, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: stop.coordinates[0],
                      lng: stop.coordinates[1],
                    }}
                    title={stopsData.length - index}
                    label={{
                      text: `${stopsData.length - index}`,
                      color: "white",
                      fontSize: "12px",
                    }}
                    icon={{
                      url: stopIcon,
                      scaledSize: { width: 30, height: 30 },
                      scale: 7,
                    }}
                    onClick={() => handleStopsMarkerClick(index)}
                  >
                    {stopsInfoWindow !== "" && stopsInfoWindow == index && (
                      <InfoWindow
                        onCloseClick={() => handleStopsMarkerClick("")}
                      >
                        <div
                          style={{
                            color: "#fff",
                            width: "280px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                          className="stops"
                        >
                          <p className="mb-2">
                            <MdOutlineWatchLater size={18} /> {stop.time}
                          </p>
                          <p className="mb-2">
                            {stop.startingTime} - {stop.endingTime}
                          </p>
                          <p className="mb-2 text-left">{stop.address}</p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              {/* Render circle for geofence (10 km radius) around current location */}
              {emergencyServiceType !== "" && (
                <Circle
                  center={{
                    lat: parseFloat(locationData[locationData.length - 1]?.lat),
                    lng: parseFloat(locationData[locationData.length - 1]?.lng),
                  }}
                  radius={10000} // Radius in meters (10 km)
                  options={{
                    strokeColor: "#2A7C76",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#2A7C76",
                    fillOpacity: 0.2,
                  }}
                />
              )}
              {fuelMarker?.length !== 0 && (
                <Marker
                  position={{
                    lat: parseFloat(fuelMarker.coordinates[0]),
                    lng: parseFloat(fuelMarker.coordinates[1]),
                  }}
                  icon={{
                    url: fuelPin,
                    scaledSize: { width: 25, height: 30 },
                    scale: 5,
                  }}
                  onClick={() => handleNearByMarkerClick(fuelMarker.address)}
                >
                  {nearByInfoWindow !== "" && (
                    <InfoWindow
                      onCloseClick={() => handleNearByMarkerClick("")}
                    >
                      <div
                        style={{
                          color: "#fff",
                          width: "200px",
                          display: "flex",
                          padding: "1px",
                          textAlign: "left",
                        }}
                        className="live"
                      >
                        <p>{fuelMarker.address}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )}
              {emergencyServiceType !== "" &&
                nearbyPlaces.map((place, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(place.coordinates[0]),
                      lng: parseFloat(place.coordinates[1]),
                    }}
                    icon={{
                      url: place.pin,
                      scaledSize: { width: 25, height: 30 },
                      scale: 5,
                    }}
                    onClick={() => handleNearByMarkerClick(index)}
                  >
                    {nearByInfoWindow !== "" && nearByInfoWindow == index && (
                      <InfoWindow
                        onCloseClick={() => handleNearByMarkerClick("")}
                      >
                        <div
                          style={{
                            color: "#fff",
                            width: "200px",
                            display: "flex",
                            padding: "1px",
                            textAlign: "left",
                          }}
                          className="live"
                        >
                          <p>{place.address}</p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              <Polyline
                path={
                  timeFilter == "today"
                    ? [...locationData, ...progress]
                    : locationData
                }
                options={{ strokeColor: "#2A7C76", strokeWeight: 5 }}
              />
              {showTraffic && <TrafficLayer />}
              {/* {directionsResults.map((directions, index) => (
              <DirectionsRenderer
                key={index}
                directions={directions}
                options={{
                  markerOptions: { visible: false },
                  polylineOptions: { strokeColor: "#2A7C76", strokeWeight: 5 },
                }}
              />
            ))}
            {directionOptions && (
              <DirectionsService
                key={directionsServiceKey}
                options={directionOptions}
                // callback={directionsCallback}
              />
            )} */}
            </GoogleMap>
          </div>
        )}
      </div>
      <div className="map-custom-icons">
        {networkStrength !== "" && (
          <div className="image-container mt-3 mb-2">
            <NetworkSvg value={networkStrength} />
          </div>
        )}
        <div
          className={
            mapType == "roadmap"
              ? `image-container mt-2 mb-2`
              : `image-container-active mt-2 mb-2`
          }
          onClick={() => toggleMapType("view-type")}
        >
          <SVG src={View} width={"20px"} />
        </div>
        <div
          className={
            showTraffic ? "image-container-active mb-2" : "image-container mb-2"
          }
          onClick={() => toggleMapType("traffic-show")}
        >
          <SVG src={TrafficSignal} width={"20px"} />
        </div>
        <div
          className={
            zoomLevel ? "image-container-active mb-2" : "image-container mb-2"
          }
          onClick={() => setZoomLevel(!zoomLevel)}
        >
          <SVG src={Zoom} width={"20px"} />
        </div>
        <div className="image-container mb-2" onClick={handleShowModal}>
          <SVG src={FileShare} width={"20px"} />
        </div>
        <div className="image-container" onClick={handleDirection}>
          {/* <SVG src={FileShare} width={'20px'} /> */}
          <MdOutlineDirections size={"25px"} />
        </div>
      </div>
      {showModalPopup && (
        <ModalPopup
          className="modal"
          show={handleShowModal}
          onHide={handleCloseModal}
          title="Share URL"
        >
          <div>
            <h6>Expire the share link after</h6>
            <div className="form-group row">
              <label className="col-sm-1 col-form-label">Days</label>
              <div className="col-sm-3">
                <select
                  className="form-control"
                  id="expireDays"
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                >
                  {Array.from({ length: 4 }, (_, i) => (
                    <option key={i} value={`${i}d`}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
              {selectedDay === "0d" && (
                <>
                  <label className="col-sm-1 mr-2 col-form-label">Hours</label>
                  <div className="col-sm-3 ml-2">
                    <select
                      className="form-control"
                      id="expireHours"
                      value={selectedHour}
                      onChange={(e) => setSelectedHour(e.target.value)}
                    >
                      {Array.from({ length: 24 }, (_, i) => (
                        <option key={i} value={`${i}h`}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
            {errorMessage && (
              <div className="text-danger" style={{ marginTop: "4px" }}>
                {errorMessage}
              </div>
            )}
            {shareUrl !== "" && (
              <div className="mt-4">
                <h6>Share link for live tracking </h6>
                <div className="d-flex justify-content-center align-items-center">
                  <input
                    type="text"
                    className="form-control"
                    value={shareUrl}
                    disabled
                  />
                  <IoIosCopy
                    size={32}
                    className="cursor-pointer ml-10"
                    onClick={copyToClipboard}
                  />
                  <WhatsappShareButton
                    url={shareUrl}
                    className="Demo__some-network__share-button ml-10"
                  >
                    <WhatsappIcon size={32} />
                  </WhatsappShareButton>
                </div>
              </div>
            )}
            <hr className="mt-3 mb-3" />
            <div className="d-flex justify-content-end mt-2">
              <button
                disabled={loading}
                className="btn btn-success"
                style={{ fontSize: "0.85rem", marginLeft: "-10px" }}
                onClick={handleCreateShareLink}
              >
                Create Share Link
              </button>
            </div>
          </div>
        </ModalPopup>
      )}
    </div>
  );
};

export default LiveMapContainer;
