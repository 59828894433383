import React, {useState, useEffect} from "react";
import { post } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
import Loading from "../../components/Loading";
import FaqIcon from "../../assets/images/faqs/faq_icon.png"

const Faqs = ()=> {
    const [faqData, setFaqData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
       title: ""
    });
    // Get faqs
    const getFaqList = async () => {
        try {
          setIsLoading(true);
          const getList = await post("/admin/faq", filters);
          setFaqData(getList.data);
          setIsLoading(false);
        } catch (error) {
          setFaqData([]);
          SweetAlert("Unable to fetch the faqs list, Try again", true);
          setIsLoading(false);
        }
    };
      useEffect(() => {
        getFaqList();
      }, [filters]);
//  marginTop : "20px"
    return (
        <div className="faq-page p-md-4 p-lg-4 p-2">
            <div className="mb-4">
                <h5 className="faq-main-title">Frequently Asked Questions</h5>
            </div>
            <div className="row">
                {isLoading && faqData.length <= 0 && <Loading />}
                {isLoading && faqData.length <= 0 && <div className="mt-2">No FAQs found!</div>}
                {!isLoading && faqData.length > 0 && faqData.map((faq, fIndex) =>(
                     <div className="col-md-6 col-lg-6 col-xs-12 mb-4"key={fIndex}>
                     <div className="card faq-card p-2">
                         <div className="card-body d-flex justify-content-center  flex-md-row flex-lg-row flex-column">
                             <img src={FaqIcon} className="mt-2" alt="" width={'25px'} height={'25px'} />
                             <div className="text-left ml-20 mt-2 mt-md-0 mt-lg-0">
                                 <h5 className="faq-page-title" style={{height : "50px", marginBottom : "20px"}}>{faq.title}</h5>
                                 {/* <br /> */}
                                 <p className="mb-0 faq-page-desc" style={{height : "130px"}}>{faq.description}</p>
                             </div>
                         </div>
                     </div>
                 </div>
                ))}
            </div>
        </div>
    )
}
export default Faqs;

