import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { post } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
import LoginBackground from "../../assets/login-background.png";
import FSLogo from "../../assets/fslogo.png";

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showLoginWithPass, setShowLoginWithPass] = useState(true);

  const loginData = showLoginWithPass
  ? { username: "", password: "" }
  : { username: "" };

  const fcmWebToken = localStorage.getItem('fcmWebToken');

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, 'Please enter valid phone number.'),
    ...(showLoginWithPass
      && { password: Yup.string().required("Password is required") })
  });

  const formik = useFormik({
    initialValues: loginData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      if (!showLoginWithPass) {
        const response = await post("/auth/send-otp", {
          'username': values.username
        });
        if (response.status === 200) {
          localStorage.setItem('username', values.username);
          navigate("/request-otp");
        } else {
          setSubmitting(false);
          SweetAlert(response.message, true);
        }
      } else {
        setSubmitting(true);
        try {
          const response = await post("/auth/login-admin", {
            'username': values.username,
            'password': values.password,
            'fcmWebToken' : fcmWebToken
          });
          if (response.status === 200) {
            SweetAlert("Login Successfully.", false);
            localStorage.setItem("ftmUserData", JSON.stringify(response.data))
            localStorage.setItem("loginToken", response.data.accessToken)
            setTimeout(() => {
              setSubmitting(false);
              navigate('/dashboard');
              window.location.reload();
            }, 1000);
          } else {
            setSubmitting(false);
            SweetAlert(response.message, true);
          }
        } catch (error) {
          setSubmitting(false);
          console.error(error);
        }
      }
    },
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        height: "100vh", // Adjust the height as needed
        position: "relative",
      }}
    >
      <div className="row m-0">
        <div className="col-md-6 col-lg-6 login-left-content d-none d-md-block d-lg-block">
          <img src={FSLogo} alt="" style={{ widht : "60px" ,height: "60px" }} />
          <p className="welcome-text">
            Welcome to FleetStakes<br />
            <span className="font-700">FleetStakes</span>
          </p>
        </div>
        <div className="col-md-4 col-lg-4 col-xs-8 login-right-content">
          <div className="card login-card">
            <div className="card-body p-5">
              <div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-center align-items-center d-md-none d-sm-none">
                <img src={FSLogo} alt="" style={{ height: "60px" }} />
              </div>
              <h6 className="text-white text-left mb-4 fs-4">Sign in</h6>
              <form
                id="login-form"
                className="login-form"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className="text-left mb-3">
                  <label className="mb-2 text-white login-input-lable">
                    Phone Number
                  </label>
                  <input
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="Enter your phone number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="field-error text-white">{formik.errors.username}</div>
                  ) : null}
                </div>
                {showLoginWithPass && <div className="text-left">
                  <label className="mb-2 text-white login-input-lable">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      type={passwordVisible ? 'text': 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <span className="input-group-text" id="basic-addon1"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                    >{passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}</span>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="field-error text-white">{formik.errors.password}</div>
                  ) : null}
                </div>}
                <div className="mt-4 d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary custom-login-btn">
                    {showLoginWithPass ? 'Login' : 'Request OTP'}
                  </button>
                  {showLoginWithPass ? <button type="button" className="btn btn-outline-primary custom-login-otp-btn" onClick={() => setShowLoginWithPass(false)}>
                    Login with OTP
                  </button> :  <button type="button" className="btn btn-outline-primary custom-login-otp-btn" onClick={() => setShowLoginWithPass(true)}>
                    Login with Password
                  </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
