import { Marker, InfoWindow, OverlayView } from "@react-google-maps/api";
import RunningVehicle from "../assets/images/running_vehicle.png";
import IdleVehicle from "../assets/images/idle_vehicle.png";
import StoppedVehicle from "../assets/images/stopped_vehicle.png";
const VehicleMarker = ({
  currentVehicleData,
  currentPosition,
  locationData,
  timeFilter,
  handleMarkerClick,
  openInfoWindow,
}) => {
  const iconUrl =
    currentVehicleData?.tripVehicleStatus === "moving"
      ? RunningVehicle
      : currentVehicleData?.tripVehicleStatus === "idle"
      ? IdleVehicle
      : StoppedVehicle;
  const angle = currentPosition?.angle || 0; // Angle you receive from the vehicle data
  return (
    <Marker
      position={{
        lat:
          timeFilter === "today"
            ? currentPosition?.lat
            : locationData[locationData.length - 1].lat,
        lng:
          timeFilter === "today"
            ? currentPosition?.lng
            : locationData[locationData.length - 1].lng,
      }}
      options={{
        optimized: true,
        icon: {
          url: iconUrl,
          scaledSize: new window.google.maps.Size(40, 40),
        },
      }}
      onClick={handleMarkerClick}
    >
      {openInfoWindow && (
        <InfoWindow onCloseClick={handleMarkerClick}>
          <div
            style={{
              color: "#fff",
              width: "250px",
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              padding: "5px",
              alignItems: "baseline",
            }}
            className="live"
          >
            <div>
              <button className="speed-button">
                {timeFilter === "today"
                  ? currentPosition?.speed
                  : locationData[locationData.length - 1].speed}
              </button>
            </div>
            <div style={{ padding: "5px" }} className="live">
              <p className="mb-2">{currentVehicleData?.vehicleNo}</p>
              <p>{currentVehicleData?.address}</p>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default VehicleMarker;