import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyBsAvYJwpjAOaH1inz-MeIslxuREIVAbac",
    authDomain: "fleetstakes-be793.firebaseapp.com",
    projectId: "fleetstakes-be793",
    storageBucket: "fleetstakes-be793.appspot.com",
    messagingSenderId: "674285672373",
    appId: "1:674285672373:web:36166eb7b284c5cbf72bfc",
    // measurementId: "G-Q4VH6Z4JZX"
}

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async ()=> {
    console.log('Requesting for client permission');
    await Notification.requestPermission().then((permission)=> {
        if (permission === 'granted') {
            console.log('User has granted permission');
            return getToken(messaging, {
                vapidKey: "BBHW1f1zJ1PzElzGokRfQkVT5c3aQ5Vs-BlmRhzY6NIYC70ZlqvPn4H87d9-dzh_kWQCgLVvtzDJ-SVK1zxv6EQ"
            })
            .then((token)=> {
                localStorage.setItem('fcmWebToken',token);
            })
            .catch((error)=> {
                console.log("there is error in generating token", error);
            })
        } else {
            console.log('user permission denied!!')
        }
    })
}

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

