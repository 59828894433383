import React, { useEffect, useState } from "react";
import {
  Circle,
  GoogleMap,
  Polygon,
  useLoadScript,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { get, post } from "../../components/api";
import { showConfirmDialog, SweetAlert } from "../../helpers/helper";
import { IoSearch } from "react-icons/io5";
import { MdShareLocation } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { LuTimer } from "react-icons/lu";
import moment from "moment";
import Translate from "../../components/Translate";
import { Link } from "react-router-dom";
import RunningVehicle from "../../assets/images/running_vehicle.png";
import IdleVehicle from "../../assets/images/idle_vehicle.png";
import StoppedVehicle from "../../assets/images/stopped_vehicle.png";
import View from "../../assets/emergency_services/view.svg";
import SVG from "react-inlinesvg";

const libraries = ["places", "drawing"];

const GeofenceDashboard = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  const toggleMapType = (type) => {
    if (type == "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      setMapType(newMapType); // Update mapType state
    }
  };
  const mapContainerStyle = {
    width: "100%",
    height: "450px",
  };

  const [data, setData] = useState([]);
  const [paths, setPaths] = useState([]);
  const [geofencetype, setGeofenceType] = useState("");
  const [geofencename, setGeofenceName] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState([]);
  const [latitude, setLatitude] = useState(23.020561);
  const [vehicleLocations, setVehicleLocations] = useState([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
  const [mapType, setMapType] = useState("roadmap");
  const [zoom, setZoom] = useState(11);
  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    trafficControlOptions: {
      mapTypeIds: ["roadmap", "satellite"],
      style: window.google.maps.TrafficStyle,
    },
  };

  useEffect(() => {
    const fetchGeofenceData = async () => {
      try {
        const ftmUserData = JSON.parse(localStorage.getItem("ftmUserData"));
        const companyid = ftmUserData.id;
        const apiResponse = await post("admin/get-All-geofence", { companyid });
        if (apiResponse.status === 200) {
          setData(apiResponse.data);
          selectGeofence(apiResponse.data[0]); // Select the first geofence
          if (apiResponse.data.length !== 0) {
            setLatitude(0);
          }
        } else if (apiResponse.status === 201) {
          SweetAlert(apiResponse.message, true);
        }
      } catch (error) {
        console.error("Error fetching geofence data:", error);
      }
    };
    const handleTrackVehicle = async () => {
      try {
        const apiResponse = await get("/admin/all-vehicle-current-loc");
        if (apiResponse.status === 200) {
          let locations = [];
          let res = apiResponse.data;
          if (res.length > 0) {
            const statusCounts = { ...vehicleStatus };
            for (let location of res) {
              locations.push({
                lat: location.coordinates[0],
                lng: location.coordinates[1],
                vehicleStatus: location.vehicle.tripVehicleStatus,
                vehicleNo: location.vehicleNo,
                address: location.address,
                id: location._id,
                show: false,
                speed: location.speed,
              });
              if (location.vehicle.tripVehicleStatus) {
                if (statusCounts[location.vehicle.tripVehicleStatus]) {
                  statusCounts[location.vehicle.tripVehicleStatus]++;
                } else {
                  statusCounts[location.vehicle.tripVehicleStatus] = 1;
                }
              }
            }
            setVehicleStatus(statusCounts);
          }
          setVehicleLocations(locations);
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      }
    };
    const fetchData = async () => {
      await fetchGeofenceData();
      await handleTrackVehicle();
    };
    fetchData();
  }, []);

  const convertCoordinatesToPaths = (coordinates) => {
    const set = coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
    console.log(set);
    return set;
  };

  const selectGeofence = (geofence) => {
    const { paths, geofencetype, geofencename } = geofence;
    setGeofenceType(geofencetype);
    let radius = paths?.radius;
    paths?.center?.length > 0
      ? setPaths([{ lat: paths?.center[1], lng: paths?.center[0], radius }])
      : setPaths(convertCoordinatesToPaths(paths?.coordinates[0]));
    setGeofenceName(geofencename);
  };

  const IconWithAddress = ({ geofence }) => {
    const { location, searchtype, latitude, longitude } = geofence;

    // Conditionally format the display text
    const displayText =
      searchtype === "place" ? location : `lat:${latitude}, lng:${longitude}`;
    return (
      <div className="d-flex align-items-start">
        <FiMapPin style={{ fontSize: "1em" }} />
        {/* Adjust the size as needed */}
        <span className="ml-2 today-km w-100">{displayText}</span>
      </div>
    );
  };
  const filterGeofence = (data, searchString) => {
    const lowerCaseSearchString = searchString.toLowerCase();
    return data.filter((geofence) =>
      geofence?.geofencename?.toLowerCase().includes(lowerCaseSearchString)
    );
    // const searchGeofence = geofences.filter((geofence) =>
    //   geofence?.geofencename?.toLowerCase().includes(lowerCaseSearchString)
    // );
    // const filteredGeofence = [...searchGeofence]

    // return filteredGeofence;
  };

  const searchGeofence = (event) => {
    if (event.target.value !== "") {
      const filteredGeofence = filterGeofence(data, event.target.value);
      setData(filteredGeofence);
    } else {
      setData(data);
    }
  };

  const handleDelete = async (geofenceId) => {
    const confirmed = await showConfirmDialog(
      "Delete!",
      "Are you sure you want to delete geofence?"
    );
    if (confirmed) {
      const apiResponse = await post("admin/delete-geofence", { geofenceId });
      console.log(apiResponse);
      if (apiResponse.status === 200) {
        SweetAlert(apiResponse?.message, false);
        setData(data.filter((data) => data._id !== geofenceId));
        selectGeofence(data[0]);
        if (data.length === 1) {
          setGeofenceType("");
          setLatitude(23.437985);
        }
      } else {
        SweetAlert(apiResponse?.message, true);
      }
    }
  };

  const handleToggleInfoWindow = (index) => {
    if (index === "") {
      setZoom(10);
    }
    console.log("1", index);
    setSelectedMarkerIndex(index);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  return (
    <>
      <div className="dashboard">
        <div className="row mb-5">
          <div className="col-md-5 col-lg-4 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-12">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <IoSearch />
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Vehicle"
                    aria-label="Vehicle"
                    aria-describedby="basic-addon1"
                    onChange={searchGeofence}
                  />
                </div>
                <div className="vehicle-list-parent">
                  {/* {data.length <= 0 && loading && <Loading />} */}
                  {data.length <= 0 && (
                    <div className="">No geofence found</div>
                  )}
                  {data.length > 0 &&
                    data?.map((geofence, index) => (
                      <div
                        // className='card text-center mb-2 cursor-pointer'
                        key={index}
                        class={
                          geofence.geofencename == geofencename
                            ? `card text-center mb-2 selected-vehicle cursor-pointer`
                            : `card text-center mb-2 cursor-pointer`
                        }
                        onClick={() => selectGeofence(geofence)}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            {/* Left-aligned geofence name and share location */}
                            <div className="d-flex align-items-center gap-2">
                              <MdShareLocation />
                              <p className="mb-0 vehicle-no">
                                {geofence?.geofencename}
                              </p>
                            </div>

                            {/* Right-aligned Edit and Delete icons */}
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "flex-end",
                                gap: "10px", // Adds space between edit and delete icons
                              }}
                            >
                              <Link
                                to={`/geofence/edit`}
                                state={{ geofence: geofence }}
                                className=""
                                style={{ marginTop: "-3px" }}
                              >
                                <MdEdit size={20} style={{ color: "black" }} />
                              </Link>
                              <MdDelete
                                size={20}
                                onClick={() => handleDelete(geofence._id)}
                              />
                            </div>
                          </div>

                          <div className="vehicle-details">
                            <div className="d-flex align-items-start">
                              <LuTimer size={16} />
                              <p className="mb-1 ml-2 today-km">
                                {moment(geofence?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </p>
                            </div>
                            <IconWithAddress geofence={geofence} />
                            <div className="d-flex align-items-start">
                              <p
                                className=""
                                style={{
                                  fontSize: "12px",
                                  color: "black",
                                  marginLeft: "24px",
                                  marginBottom: "0px",
                                  marginTop: "3px",
                                  fontWeight: "600",
                                }}
                              >
                                {`Vehicles inside Geofence : ${
                                  geofence?.vehicles?.filter((vehicle) =>
                                    geofence?.vehiclesAllowed?.includes(vehicle)
                                  ).length || 0
                                }`}
                                {/* {`Vehicles inside Geofence : ${
                                  geofence?.vehicles?.length || 0
                                }`} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-8 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-12">
                <div className="card custom-card">
                  <div className="card-header d-flex justify-content-between">
                    <h6>
                      <Translate>map</Translate>
                    </h6>
                    <div className="d-flex">
                      <Link
                        to={"/geofence/create"}
                        className="mb-1 vehicle-filter"
                      >
                        Create new geofence
                      </Link>
                    </div>
                  </div>
                  <div
                    className="card-body google-map-parent"
                    style={{ position: "relative" }}
                  >
                    {console.log(latitude)}
                    <div className="google-map-parent">
                      <GoogleMap
                        zoom={zoom}
                        // zoom={latitude !== 23.020561 ? zoom : 8}
                        mapContainerStyle={mapContainerStyle}
                        center={{
                          lat: paths[0]?.lat || 23.020561,
                          lng: paths[0]?.lng || 72.583612,
                        }}
                        options={mapOptions}
                      >
                        {geofencetype === "" ? (
                          <></>
                        ) : geofencetype === "circle" ? (
                          <Circle
                            center={{ lat: paths[0]?.lat, lng: paths[0]?.lng }}
                            radius={paths[0]?.radius}
                            options={{
                              fillColor: "#2A7C76",
                              strokeColor: "#1b1b1b",
                              fillOpacity: 0.5,
                              strokeWeight: 1,
                            }}
                          />
                        ) : (
                          <Polygon
                            path={paths}
                            options={{
                              fillColor: "#2A7C76",
                              strokeColor: "#1b1b1b",
                              fillOpacity: 0.5,
                              strokeWeight: 1,
                            }}
                          />
                        )}
                        <MarkerClusterer>
                          {(clusterer) =>
                            vehicleLocations.map((markerData, index) => (
                              <Marker
                                key={markerData?.vehicleNo}
                                icon={{
                                  url:
                                    markerData.vehicleStatus == "moving"
                                      ? RunningVehicle
                                      : markerData.vehicleStatus == "idle"
                                      ? IdleVehicle
                                      : StoppedVehicle,
                                  scaledSize: { width: 50, height: 50 },
                                  scale: 7,
                                }}
                                position={{
                                  lat: markerData.lat,
                                  lng: markerData.lng,
                                }}
                                clusterer={clusterer}
                                onClick={() => handleToggleInfoWindow(index)}
                              >
                                {selectedMarkerIndex !== "" &&
                                  selectedMarkerIndex == index && (
                                    <InfoWindow
                                      onCloseClick={() =>
                                        handleToggleInfoWindow("")
                                      } // Close info window on click outside
                                    >
                                      <div
                                        style={{
                                          color: "#fff",
                                          width: "250px",
                                          display: "flex",
                                          flexDirection: "row",
                                          textAlign: "left",
                                          padding: "5px",
                                          alignItems: "baseline",
                                        }}
                                        className="live"
                                      >
                                        {/* <div>
                                        <button  className='speed-button'>{markerData?.speed?.speed || 0}</button>
                                      </div> */}
                                        <div
                                          style={{ padding: "5px" }}
                                          className="live"
                                        >
                                          <Link
                                            to={`/vehicles/${markerData.vehicleNo}`}
                                            state={{ id: markerData.id }}
                                            className="text-white font-600"
                                          >
                                            <p className="mb-2">
                                              {markerData.vehicleNo}
                                            </p>
                                          </Link>
                                          <p>{markerData.address}</p>
                                        </div>
                                      </div>
                                    </InfoWindow>
                                  )}
                              </Marker>
                            ))
                          }
                        </MarkerClusterer>
                      </GoogleMap>
                    </div>
                    <div className="map-custom-icons">
                      <div
                        className={
                          mapType == "roadmap"
                            ? `image-container mt-3 mb-2`
                            : `image-container-active mt-3 mb-2`
                        }
                        onClick={() => toggleMapType("view-type")}
                      >
                        <SVG src={View} width={"20px"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeofenceDashboard;
