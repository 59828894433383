import React from "react";
import Dashboard from "../pages/Dashboard";
import User from "../pages/User";
import Vehicles from "../pages/Vehicles";
import VehicleInfo from "../pages/VehicleInfo";
import VehiclePlayRoute from "../pages/VehicleRoute";
import GeofenceDashboard from "../pages/GeofenceDashboard";
import Geofence from "../pages/Geofence";
import FuelTheft from "../pages/FuelTheft";
import FleetReports from "../pages/Report";
import FleetReportDetails from "../pages/ReportDetails";
import Orders from "../pages/Orders";
import BuyGps from "../pages/BuyGps";
import BuyGpsFuel from "../pages/BuyGpsFuel";
import Faqs from "../pages/Faqs";
import Setting from "../pages/Setting";
import TripCalculator from "../pages/TripCalculator";
import Support from "../pages/Support";
import Profile from "../pages/Profile";
import Documents from "../pages/Documents";
import ProfileEdit from "../pages/ProfileEdit";
import { PiUsersThreeLight } from "react-icons/pi";
import { CiRoute } from "react-icons/ci";
import HomeSvg from "../assets/images/icons/home.svg";
import VehiclesSvg from "../assets/images/icons/vehicles.svg";
import ReportsSvg from "../assets/images/icons/reports.svg";
import OrdersSvg from "../assets/images/icons/orders.svg";
import FaqsSvg from "../assets/images/icons/faqs.svg";
import TripCalCulatorsSvg from '../assets/images/icons/TripCalculator.svg'
import DocumentsSvg from '../assets/images/icons/documents.svg'
import SupportSvg from "../assets/images/icons/support.svg";

export const CompanyRoutesConfig = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        // permission: "view_dashboard",
        // icon: <></>,
        src : HomeSvg
    },
    {
        path: "/users",
        name: "User",
        component: User,
        // permission: "view_dashboard",
        icon: <PiUsersThreeLight size = "25px"/>,
        src : ""
    },
    {
        path: "/vehicles",
        name: "Vehicles",
        component: Vehicles,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : VehiclesSvg
    },
    {
        path: "/vehicles/:id",
        name: "VehicleInfo",
        component: VehicleInfo,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/playback/:id",
        name: "PlayRoute",
        component: VehiclePlayRoute,
        src : ""
    },
    {
        path: "/geofence",
        name: "GeofenceDashboard",
        component: GeofenceDashboard,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/geofence/create",
        name: "Geofence",
        component: Geofence,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/fuel-theft",
        name: "FuelTheft",
        component: FuelTheft,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/fleet-reports",
        name: "FleetReports",
        component: FleetReports,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ReportsSvg
    },
    {
        path: "/fleet-reports/:slug",
        name: "FleetReportDetails",
        component: FleetReportDetails,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/orders",
        name: "Orders",
        component: Orders,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : OrdersSvg
    },
    {
        path: "/plans/buy-gps",
        name: "BuyGps",
        component: BuyGps,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/plans/buy-gps-fuel",
        name: "BuyGpsFuel",
        component: BuyGpsFuel,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/faqs",
        name: "Faqs",
        component: Faqs,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : FaqsSvg
    },
    {
        path: "/settings",
        name: "Setting",
        component: Setting,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/trip-calculator",
        name: "TripCalculator",
        component: TripCalculator,
        // permission: "view_dashboard",
        icon: <CiRoute size = "25px"/>,
        src : ""
    },
    {
        path: "/support",
        name: "Support",
        component: Support,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : SupportSvg
    },
    {
        path: "/profile/:id",
        name: "Profile",
        component: Profile,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : ""
    },
    {
        path: "/documents",
        name: "Documents",
        component: Documents,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src : DocumentsSvg
    },
    {
        path: "/profile/edit/:id",
        name: "ProfileEdit",
        component: ProfileEdit,
        // permission: "view_dashboard",
        // icon: <MdDashboard />
        src :""
    },
];