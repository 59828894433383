// AutocompleteInput.js
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';

const AutocompleteInput = ({ onPlaceSelected, name, value, options = {}, ...rest }) => {
  return (
    <Autocomplete
      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      onPlaceSelected={(place) => onPlaceSelected(place, name)}
      options={{
        ...options,
        types: ['geocode','establishment'],
        componentRestrictions: { country: 'in' }
      }}
      {...rest}
      inputprops={{ name, value }}
    />
  );
};

export default AutocompleteInput;
// import React, { useEffect, useRef, useState } from 'react';
// import Autocomplete from 'react-google-autocomplete';
// const AutocompleteInput = ({ value = '', onPlaceSelected, className }) => {
//   const [inputValue, setInputValue] = useState(value);
//   const autocompleteRef = useRef(null);

//   useEffect(() => {
//     setInputValue(value || '');
//   }, [value]);

//   const handlePlaceChanged = () => {
//     if (autocompleteRef.current) {
//       const place = autocompleteRef.current.getPlace();
//       console.log(place)
//       if (place && onPlaceSelected) {
//         onPlaceSelected(place);
//       }
//     }
//   };

//   return (
//     <Autocomplete
//       onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
//       onPlaceChanged={handlePlaceChanged}
//     >
//       <input
//         type="text"
//         className={className}
//         value={inputValue}
//         onChange={(e) => setInputValue(e.target.value)}
//         placeholder="Enter location"
//       />
//     </Autocomplete>
//   );
// };

// export default AutocompleteInput;