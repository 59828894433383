import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Topbar from "../common/Topbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumb from '../components/Breadcrumb';


export default function ProtectedRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const ftmUserData = localStorage.getItem('ftmUserData');
  const [className, setClassName] = useState("content");
  const callback = (state)=> state;
  const states = useSelector(callback);

  // useEffect(() => {
  //   // Redirect to login page 
  //   if (ftmUserData == undefined || ftmUserData == null || ftmUserData == '') {
  //     navigate("/login");
  //   }
  // },[])
  

  useEffect(() => {
    // Check if the current path is a share page
    const isSharePage = location.pathname.includes("/share");
    // Redirect to login page if not authenticated and not on the share page
    if (!ftmUserData && !isSharePage) {
      navigate("/login");
    }
  }, [ftmUserData, navigate, location.pathname]);

  useEffect (()=> {
    if (states?.collapsable?.collapsed == true) {
      setClassName("collapsedContent")
    } else {
      setClassName('content')
    }
  }, [states.collapsable.collapsed])

  return (
    <>
      <Header />
      <div className={className}>
        <Topbar />
        <div className="main-content">
          <Breadcrumb />
          <Outlet />
        </div>
      </div>
    </>
  );
}
