import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import SVG from "react-inlinesvg";
import View from "../assets/emergency_services/view.svg";
import RunningVehicle from "../assets/images/running_vehicle.png";
import IdleVehicle from "../assets/images/idle_vehicle.png";
import StoppedVehicle from "../assets/images/stopped_vehicle.png";
import { Link } from "react-router-dom";

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

export default function MapContainer(props) {
  const { locations1, selectVehicleNo } = props;
  const [mapType, setMapType] = useState("roadmap");
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
  const mapRef = useRef(null);

  useEffect(() => {
    if (selectVehicleNo !== "" && locations1.length > 0) {
      const selectedVehicle = locations1.find(
        (markerData) => markerData.vehicleNo === selectVehicleNo
      );

      if (selectedVehicle && mapRef.current) {
        mapRef.current.panTo({
          lat: selectedVehicle.lat,
          lng: selectedVehicle.lng,
        });
        mapRef.current.setZoom(14);
      }
    }
  }, [selectVehicleNo, locations1]);

  useEffect(() => {
    if (mapRef.current) {
      if (locations1.length > 1) {
        const bounds = new window.google.maps.LatLngBounds();
        if (selectVehicleNo === "") {
          locations1.forEach((marker) => {
            if (marker.lat && marker.lng) {
              bounds.extend(
                new window.google.maps.LatLng(marker.lat, marker.lng)
              );
            }
          });
          mapRef.current.fitBounds(bounds);
        } else {
        }
      } else if (locations1.length === 1) {
        mapRef.current.panTo({
          lat: locations1[0].lat,
          lng: locations1[0].lng,
        });
        mapRef.current.setZoom(10);
      } else {
        mapRef.current.panTo({ lat: 23.02782, lng: 72.574575 });
        mapRef.current.setZoom(11);
      }
    }
  }, [locations1, mapRef.current]);

  const handleLoadMap = (map) => {
    mapRef.current = map;
    if (locations1.length > 0) {
      console.log("1", locations1.length);
      const bounds = new window.google.maps.LatLngBounds();
      locations1.forEach((marker) => {
        if (marker.lat && marker.lng) {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        }
      });
      map.fitBounds(bounds);
    } else {
      console.log("handleLoad else");
      mapRef.current.panTo({ lat: 23.02782, lng: 72.574575 });
      mapRef.current.setZoom(11);
    }
  };

  useEffect(() => {
    const selectedIndex = locations1.findIndex(
      (markerData) => markerData.vehicleNo === selectVehicleNo
    );
    setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
  }, [selectVehicleNo, locations1]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const toggleMapType = (type) => {
    if (type === "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      setMapType(newMapType);
    }
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const smoothZoom = (map, targetZoom, position) => {
    let currentZoom = map.getZoom();
    const step = targetZoom > currentZoom ? 1 : -1;

    function zoom() {
      if (currentZoom !== targetZoom) {
        currentZoom += step;
        map.setZoom(currentZoom);

        // Only pan to valid positions
        if (position && position.lat && position.lng) {
          map.panTo(position);
        }

        setTimeout(zoom, 200);
      }
    }

    zoom();
  };

  const handleToggleInfoWindow = (index) => {
    if (index !== "") {
      setSelectedMarkerIndex(index);
      const position = locations1[index];

      // If zoom is less than a threshold, zoom in smoothly and center during zoom
      if (mapRef.current.getZoom() < 14) {
        smoothZoom(mapRef.current, 14, {
          lat: position.lat,
          lng: position.lng,
        });
      } else {
        mapRef.current.panTo({ lat: position.lat, lng: position.lng });
      }
    } else {
      setSelectedMarkerIndex("");
      smoothZoom(mapRef.current, 12, null);
    }
  };

  return (
    <div>
      <div className="google-map-parent">
        <GoogleMap
          onLoad={handleLoadMap}
          mapContainerStyle={mapContainerStyle}
          options={mapOptions}
        >
          <MarkerClusterer>
            {(clusterer) =>
              locations1.map((markerData, index) => (
                <Marker
                  key={markerData?.vehicleNo}
                  icon={{
                    url:
                      markerData.vehicleStatus === "moving"
                        ? RunningVehicle
                        : markerData.vehicleStatus === "idle"
                        ? IdleVehicle
                        : StoppedVehicle,
                    scaledSize: { width: 50, height: 50 },
                  }}
                  position={{ lat: markerData.lat, lng: markerData.lng }}
                  clusterer={clusterer}
                  onClick={() => handleToggleInfoWindow(index)}
                  zIndex={selectedMarkerIndex === index ? 1000 : 1}
                >
                  {selectedMarkerIndex !== "" &&
                    selectedMarkerIndex === index && (
                      <InfoWindow
                        onCloseClick={() => handleToggleInfoWindow("")} // Close info window
                      >
                        <div
                          style={{
                            color: "#fff",
                            width: "250px",
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "left",
                            padding: "5px",
                            alignItems: "baseline",
                          }}
                          className="live"
                        >
                          <div>
                            <button className="speed-button">
                              {markerData?.speed || 0}
                            </button>
                          </div>
                          <div style={{ padding: "5px" }} className="live">
                            <Link
                              to={`/vehicles/${markerData.vehicleNo}`}
                              state={{ id: markerData.id }}
                              className="text-white font-600"
                            >
                              <p className="mb-2">{markerData.vehicleNo}</p>
                            </Link>
                            <p>{markerData.address}</p>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </div>
      <div className="map-custom-icons-dashboard">
        <div
          className={
            mapType === "roadmap"
              ? `image-container mt-3 mb-2`
              : `image-container-active mt-3 mb-2`
          }
          onClick={() => toggleMapType("view-type")}
        >
          <SVG src={View} width={"20px"} />
        </div>
      </div>
    </div>
  );
}
