import React from "react";
import { Spring } from "react-spring";

const VerticalProgress = ({ progress, vehicleData }) => {
  const formatMileage = (value) => {
    return Number(value).toFixed(2);
  };
  return (
    <Spring from={{ percent: 0 }} to={{ percent: progress }}>
      {({ percent }) => {
        return (
          <div className={`d-flex justify-content-between p-3`}>
            <div className="d-flex">
              <div className="progress-label">
                {/* Render percentage labels from 0% to 100% */}
                {[...Array(6).keys()].reverse().map((num) => (
                  <div key={num} className="label-item">
                    {num * 20}% -
                  </div>
                ))}
              </div>
              <div className="progress vertical">
                <div
                  style={{ height: `${progress}%` }}
                  className="progress-bar"
                >
                  <span className="sr-only">{`${progress}%`}</span>
                </div>
              </div>
            </div>
            <div className="fuel-info">
              <div className="text-right mb-2">
                <p className="mb-1 title">Total tank capacity</p>
                <p className="mb-1 value">
                  {vehicleData.totalTankCapacity} ltr
                </p>
              </div>
              <div className="text-right mb-2">
                <p className="mb-1 title">Current Fuel Level</p>
                <p className="mb-1 value">{vehicleData.currentFuel} ltr</p>
              </div>
              <div className="text-right mb-2">
                <p className="mb-1 title">Total Mileage</p>
                <p className="mb-1 value">
                  {formatMileage(vehicleData.mileage)}
                </p>
              </div>
              <div className="text-right mb-2">
                <p className="mb-1 title">Total Distance</p>
                <p className="mb-1 value">{vehicleData.distance} KM</p>
              </div>
            </div>
          </div>
        );
      }}
    </Spring>
  );
};

export default VerticalProgress;
