import React from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: 'rgba(248, 249, 249, 1)',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '0px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

const DataTablePagination1 = ({
  title,
  columns,
  data,
  totalRows,
  page,
  perPage,
  onPageChange,
  onPerPageChange,
}) => {
  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationPerPage={perPage}
      paginationRowsPerPageOptions={[10, 20, 30]}
      paginationDefaultPage={page}
      onChangePage={onPageChange}
      onChangeRowsPerPage={onPerPageChange}
      customStyles={customStyles}
      highlightOnHover
      noDataComponent="Records not found"
    />
  );
};

export default React.memo(DataTablePagination1);



















// import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
// import { post } from "./api";

// const customStyles = {
//   headRow: {
//     style: {
//       border: "none",
//       backgroundColor: "rgba(248, 249, 249, 1)",
//     },
//   },
//   headCells: {
//     style: {
//       color: "#202124",
//       fontSize: "14px",
//     },
//   },
//   rows: {
//     highlightOnHoverStyle: {
//       backgroundColor: "rgb(230, 244, 244)",
//       borderBottomColor: "#FFFFFF",
//       borderRadius: "0px",
//       outline: "1px solid #FFFFFF",
//     },
//   },
//   pagination: {
//     style: {
//       border: "none",
//     },
//   },
// };

// const DataTablePagination1 = React.memo(
//   ({
//     title,
//   columns,
//   data,
//   totalRows,
//   page,
//   perPage,
//   onPageChange,
//   onPerPageChange,
//   }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [totalRows, setTotalRows] = useState();
//     const [showPagination, setShowPagination] = useState(false);

//     //   const fetchUsers = async (page, perPage, query) => {
//     //     setLoading(true);
//     //     try {
//     //       const response = await post(`${apiUrl}`, {
//     //         options: {
//     //           page: page,
//     //           limit: perPage
//     //         },
//     //         query: query
//     //       });
//     //       // .docs
//     //       // .data
//     //       // .data
//     //       setData(response.data);
//     //       setTotalRows(response.totalDocs);
//     //       setShowPagination(response.totalDocs > perPage);

//     //     } catch (error) {
//     //       console.error('Error fetching data:', error);
//     //     }
//     //     setLoading(false);
//     //   };

//     // const handlePageChange = page => {
//     //   fetchUsers(page, perPage, query);
//     // };

//     // const handlePerRowsChange = async newPerPage => {
//     //   setPerPage(newPerPage);
//     //   fetchUsers(1, newPerPage, query);
//     // };

//     const handlePageChange = (newPage) => {
//       setPage(newPage); // Update page in parent component
//     };

//     // Handle rows per page change
//     const handlePerRowsChange = async (newPerPage, newPage) => {
//       setPerPage(newPerPage); // Update perPage in parent component
//       setPage(newPage); // Reset to first page after changing perPage
//     };

//     //   useEffect(() => {
//     //     fetchUsers(1, perPage, queryObj);
//     //   }, [queryObj, updateState]); // Only re-fetch data when queryObj changes

//     //   useEffect(() => {
//     //     fetchUsers(1, perPage, query);
//     //   }, [perPage, query]); // Only re-fetch when perPage or query changes

//     const CustomTitle = ({ title }) => (
//       <div className="custom-title">{title}</div>
//     );
//     console.log(data);
//     return (
//       <DataTable
//         title={title ? <CustomTitle title={title} /> : null}
//         columns={columns}
//         data={data}
//         progressPending={loading}
//         pagination={showPagination}
//         paginationServer
//         paginationPerPage={perPage}
//         paginationRowsPerPageOptions={[10, 20, 30]}
//         paginationTotalRows={totalRows}
//         onChangeRowsPerPage={handlePerRowsChange}
//         onChangePage={handlePageChange}
//         customStyles={customStyles}
//         className={pageName}
//         highlightOnHover
//         noDataComponent="Records not found"
//       />
//     );
//   }
// );

// export default DataTablePagination1;
