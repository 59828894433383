import React, { useState, useEffect, useMemo } from "react";
import print from "print-js";
import "./styles.css";
import DataTablePagination from "../../components/Datatable";
import { Badge, Dropdown } from "react-bootstrap";
import Download from "../../assets/images/invoice/download.png";
import Print from "../../assets/images/invoice/print.png";
import { Lightbox } from "react-modal-image";
import Delete from "../../assets/images/invoice/Delete.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  SweetAlert,
  getUserIdFromLC,
  showConfirmDialog,
} from "../../helpers/helper";
import { get, post } from "../../components/api";
import { useFormik } from "formik";
import * as Yup from "yup";

const Documents = () => {
  const [vehicles, setVehicles] = useState([]);
  const userId = getUserIdFromLC();
  useEffect(() => {
    handleToggleColumn("license");
    const getVehicles = async () => {
      const vehiclesData = await get("/admin/vehicles-list");
      if (vehiclesData.status == 200) {
        setVehicles(vehiclesData.data);
      } else {
        setVehicles([]);
      }
    };
    getVehicles();
  }, []);

  const addDocumentSchema = Yup.object().shape({
    type: Yup.string().required("Please select document type."),
    vehicleNo: Yup.string().required("Please add vehicle no"),
    documentNo: Yup.string().required("Please add document no"),
    documentImg: Yup.string().required("Please add document image"),
    expirationDate: Yup.string().required("Please add expiration date"),
    insuranceCompanyName: Yup.string(),
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const [toggleColumn, setToggleColumn] = useState("license");
  const [showImage, setShowImage] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [column, setColumn] = useState([]);
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [tablePaginationUpdate, setTablePaginationUpdate] = useState(false);
  const formData = new FormData();
  const [showCompanyInsurance, setShowCompanyInsurance] = useState(false);

  const handleCloseDocument = () => {
    formik.handleReset();
    setDocumentType("");
    setStartDate("");
    setShowAddDocumentModal(false);
  };

  let vehicleOption = [];
  const createVehicleOption = () => {
    vehicles.forEach((vehicle) => {
      vehicleOption.push({
        value: `${vehicle.vehicleNo}`,
        label: `${vehicle.vehicleNo}`,
      });
    });
  };
  createVehicleOption(vehicles);

  const documentData = {
    type: "",
    vehicleNo: "",
    documentNo: "",
    documentImg: "",
    expirationDate: "",
    insuranceCompanyName: "",
  };

  const handleDocumentType = (documentObj) => {
    setDocumentType(documentObj);
    formik.setFieldValue("type", documentObj.value);
    if (documentObj.value == "insurance") {
      setShowCompanyInsurance(true);
    } else {
      setShowCompanyInsurance(false);
    }
  };

  const handleVehicleNo = (vehicleObj) => {
    setVehicle(vehicleObj);
    formik.setFieldValue("vehicleNo", vehicleObj.value);
  };

  const handleDateType = (date) => {
    setStartDate(date);
    formik.setFieldValue("expirationDate", moment(date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    handleToggleColumn(toggleColumn);
  }, [toggleColumn]);

  const handleShowImage = (imgLink) => {
    setImgLink(imgLink);
    setShowImage(true);
  };

  const downloadDocument = async (documentUrl) => {
    await fetch(documentUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const mimeType = getFileExtension(documentUrl);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${Date.now()}.${mimeType}` || "downloaded-file.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        SweetAlert("Document downloaded successfully", false);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
        SweetAlert("Unable to download document, Try again", true);
      });
  };

  function getFileExtension(url) {
    // Use a regular expression to match the file extension
    const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
    return match ? match[1] : null;
  }

  const handlePrintDocument = async (imgUrl) => {
    print(imgUrl);
  };

  const handleDeleteApi = async (documentId) => {
    const showDialog = await showConfirmDialog(
      "Delete Document",
      "Are you sure you want to delete document?",
      "Confirm",
      "Cancel"
    );
    if (showDialog) {
      const deleteDoc = await post("/admin/document/delete", {
        documentId: documentId,
      });
      if (deleteDoc.status == 200) {
        SweetAlert("Document deleted successfully", false);
        setToggleColumn(toggleColumn);
        setTablePaginationUpdate(!tablePaginationUpdate);
      } else {
        SweetAlert("Unable to delete the document, Try again", true);
        setToggleColumn(toggleColumn);
      }
    }
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const licenseColumn = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicleNo,
      sortable: false,
    },
    {
      name: "License No",
      selector: (row) => row.documentNo,
      sortable: false,
    },
    {
      name: "Date Added",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == true ? (
          <div className="invoice-badge">
            <Badge bg="success">Active</Badge>
          </div>
        ) : (
          <div className="invoice-badge">
            <Badge bg="danger">Expired</Badge>
          </div>
        ),
      sortable: false,
    },
    {
      name: "License",
      selector: (row) => {
        const mimeType = getFileExtension(row.documentImg);
        if (mimeType == "jpeg" || mimeType == "jpg" || mimeType == "png") {
          return (
            <div>
              <a
                className="linkViewImage"
                onClick={() => handleShowImage(row.documentImg)}
              >
                Click To View
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <a
                className="linkViewImage"
                href={row.documentImg}
                target="_blank"
              >
                Click To View
              </a>
            </div>
          );
        }
      },
      sortable: false,
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu size="sm" title="Actions" className="action-dropdown">
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => downloadDocument(row.documentImg)}
              >
                <img src={Download} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Download</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handlePrintDocument(row.documentImg)}
              >
                <img src={Print} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Print</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handleDeleteApi(row._id)}
              >
                <img src={Delete} alt="" width={"20px"} height={"20px"} />
                <span
                  className="invoice-btn-text"
                  style={{ color: "rgba(232, 70, 70, 1)" }}
                >
                  Delete
                </span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowoverflow: "true",
      button: "true",
      width: "56px",
    },
  ];
  const insuranceColumn = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicleNo,
      sortable: false,
    },
    {
      name: "Insurance Id",
      selector: (row) => row.documentNo,
      sortable: false,
    },
    {
      name: "Company Name",
      selector: (row) => row.insuranceCompanyName,
      sortable: false,
    },
    {
      name: "Expiration Date",
      selector: (row) => row.documentExpirationDate,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == true ? (
          <div className="invoice-badge">
            <Badge bg="success">Active</Badge>
          </div>
        ) : (
          <div className="invoice-badge">
            <Badge bg="danger">Expired</Badge>
          </div>
        ),
      sortable: false,
    },
    {
      name: "Insurance",
      selector: (row) => {
        const mimeType = getFileExtension(row.documentImg);
        if (mimeType == "jpeg" || mimeType == "jpg" || mimeType == "png") {
          return (
            <div>
              <a
                className="linkViewImage"
                onClick={() => handleShowImage(row.documentImg)}
              >
                Click To View
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <a
                className="linkViewImage"
                href={row.documentImg}
                target="_blank"
              >
                Click To View
              </a>
            </div>
          );
        }
      },
      sortable: false,
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu size="sm" title="Actions" className="action-dropdown">
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => downloadDocument(row.documentImg)}
              >
                <img src={Download} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Download</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handlePrintDocument(row.documentImg)}
              >
                <img src={Print} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Print</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handleDeleteApi(row._id)}
              >
                <img src={Delete} alt="" width={"20px"} height={"20px"} />
                <span
                  className="invoice-btn-text"
                  style={{ color: "rgba(232, 70, 70, 1)" }}
                >
                  Delete
                </span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowoverflow: "true",
      button: "true",
      width: "56px",
    },
  ];
  const pucColumn = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicleNo,
      sortable: false,
    },
    {
      name: "Expiration Date",
      selector: (row) => row.documentExpirationDate,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == true ? (
          <div className="invoice-badge">
            <Badge bg="success">Active</Badge>
          </div>
        ) : (
          <div className="invoice-badge">
            <Badge bg="danger">Expired</Badge>
          </div>
        ),
      sortable: false,
    },
    {
      name: "PUC",
      selector: (row) => {
        const mimeType = getFileExtension(row.documentImg);
        if (mimeType == "jpeg" || mimeType == "jpg" || mimeType == "png") {
          return (
            <div>
              <a
                className="linkViewImage"
                onClick={() => handleShowImage(row.documentImg)}
              >
                Click To View
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <a
                className="linkViewImage"
                href={row.documentImg}
                target="_blank"
              >
                Click To View
              </a>
            </div>
          );
        }
      },
      sortable: false,
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu size="sm" title="Actions" className="action-dropdown">
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => downloadDocument(row.documentImg)}
              >
                <img src={Download} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Download</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handlePrintDocument(row.documentImg)}
              >
                <img src={Print} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Print</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handleDeleteApi(row._id)}
              >
                <img src={Delete} alt="" width={"20px"} height={"20px"} />
                <span
                  className="invoice-btn-text"
                  style={{ color: "rgba(232, 70, 70, 1)" }}
                >
                  Delete
                </span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowoverflow: "true",
      button: "true",
      width: "56px",
    },
  ];
  const rcColumn = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicleNo,
      sortable: false,
    },
    {
      name: "Expiration Date",
      selector: (row) => row.documentExpirationDate,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == true ? (
          <div className="invoice-badge">
            <Badge bg="success">Active</Badge>
          </div>
        ) : (
          <div className="invoice-badge">
            <Badge bg="danger">Expired</Badge>
          </div>
        ),
      sortable: false,
    },
    {
      name: "RC",
      selector: (row) => {
        const mimeType = getFileExtension(row.documentImg);
        if (mimeType == "jpeg" || mimeType == "jpg" || mimeType == "png") {
          return (
            <div>
              <a
                className="linkViewImage"
                onClick={() => handleShowImage(row.documentImg)}
              >
                Click To View
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <a
                className="linkViewImage"
                href={row.documentImg}
                target="_blank"
              >
                Click To View
              </a>
            </div>
          );
        }
      },
      sortable: false,
    },
    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu size="sm" title="Actions" className="action-dropdown">
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => downloadDocument(row.documentImg)}
              >
                <img src={Download} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Download</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handlePrintDocument(row.documentImg)}
              >
                <img src={Print} alt="" width={"20px"} height={"20px"} />
                <span className="invoice-btn-text">Print</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className="d-flex align-items-center"
                onClick={() => handleDeleteApi(row._id)}
              >
                <img src={Delete} alt="" width={"20px"} height={"20px"} />
                <span
                  className="invoice-btn-text"
                  style={{ color: "rgba(232, 70, 70, 1)" }}
                >
                  Delete
                </span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      allowoverflow: "false",
      button: "true",
      width: "56px",
    },
  ];

  const documentTypeOptions = [
    { value: "license", label: "License" },
    { value: "insurance", label: "Insurance" },
    { value: "puc", label: "PUC" },
    { value: "rc", label: "RC" },
  ];

  const handleToggleColumn = (columnName) => {
    setToggleColumn(columnName);
    switch (columnName) {
      case "license":
        setColumn(licenseColumn);
        break;
      case "insurance":
        setColumn(insuranceColumn);
        break;
      case "puc":
        setColumn(pucColumn);
        break;
      case "rc":
        setColumn(rcColumn);
        break;
      default:
        setColumn([]);
        break;
    }
  };

  const handleTabsChange = (key) => {
    handleToggleColumn(key);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <BsThreeDotsVertical size={"20px"} color="#2A7C7A" />
    </a>
  ));

  const formik = useFormik({
    initialValues: documentData,
    validationSchema: addDocumentSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (isValidFileType(values.documentImg.name)) {
          formData.append("type", values.type);
          formData.append("vehicleNo", values.vehicleNo);
          formData.append("documentNo", values.documentNo);
          formData.append("documentImg", values.documentImg);
          formData.append(
            "expirationDate",
            moment(values.expirationDate).utc()
          );
          if (values.type == "insurance") {
            formData.append("companyName", values.insuranceCompanyName);
          }
          const response = await post("/admin/document/add", formData);
          if (response.status == 200) {
            SweetAlert(response.message, false);
            handleCloseDocument();
            setTablePaginationUpdate(!tablePaginationUpdate);
          } else {
            SweetAlert(response.message, true);
            handleCloseDocument();
          }
        } else {
          SweetAlert("Upload jpg, png or pdf file only", true);
        }
      } catch (error) {
        console.log(error.message);
        SweetAlert("Unable to add the document");
      }
    },
  });

  // check valid file type
  function isValidFileType(fileName) {
    const allowedExtensions = ["jpeg", "jpg", "png", "pdf"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  // Memoized query object
  const queryObj = useMemo(
    () => [{ documentType: toggleColumn }, { clientId: userId }],
    [toggleColumn, userId]
  );

  return (
    <>
      {showImage ? (
        <>
          <div>
            <Lightbox
              medium={imgLink}
              large={imgLink}
              alt="img.jpg"
              hideDownload
              hideZoom
              onClose={() => setShowImage(false)}
            />
          </div>
        </>
      ) : null}
      <div className="support-page p-4">
        <div className="row p-3">
          <div className="row mt-2">
            <div className="col-md-12 col-lg-12 col-xs-12 text-end">
              <button
                className="btn btn-primary custom-submit-btn"
                onClick={() => setShowAddDocumentModal(true)}
                style={{ width: "150px", height: "40px" }}
              >
                + Add Document
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="col-md-12 col-lg-12 col-xs-12"
              style={{
                backgroundColor: "white",
                borderRadius: "6px",
                padding: "10px",
              }}
            >
              <div className="documentTabWrapper" id="documentPageId">
                <Tabs
                  defaultActiveKey="license"
                  id="uncontrolled-tab-example"
                  className="mb-4"
                  onSelect={(key) => handleTabsChange(key)}
                >
                  <Tab
                    eventKey="license"
                    title="License"
                    color="black"
                    style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    onClick={() => handleToggleColumn("license")}
                  ></Tab>
                  <Tab
                    eventKey="insurance"
                    title="Insurance"
                    color="black"
                    style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    onClick={() => handleToggleColumn("insurance")}
                  ></Tab>
                  <Tab
                    eventKey="puc"
                    title="PUC"
                    color="black"
                    style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    onClick={() => handleToggleColumn("puc")}
                  ></Tab>
                  <Tab
                    eventKey="rc"
                    title="RC"
                    color="black"
                    style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                    onClick={() => handleToggleColumn("rc")}
                  ></Tab>
                </Tabs>
              </div>
              <div className="datatableResponsive">
                <DataTablePagination
                  title=""
                  columns={column}
                  paginationTotalRows={10}
                  apiUrl={"/admin/document/list"}
                  queryObj={queryObj}
                  pageName={"documents"}
                  updateState={tablePaginationUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddDocumentModal && (
        <>
          <Modal
            show={showAddDocumentModal}
            centered
            backdrop="static"
            onHide={handleCloseDocument}
          >
            <Modal.Header closeButton>
              <Modal.Title className="reportModalTitle">
                Add Document
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="documentTypeWrapper">
                    <p className="documentTypeLabel">Select Document Type</p>
                    <Select
                      options={documentTypeOptions}
                      styles={customStyles}
                      defaultValue={documentTypeOptions[0]}
                      onChange={(e) => handleDocumentType(e)}
                      value={documentType}
                      onBlur={(e) => formik.handleBlur(e)}
                    />
                    {formik.touched.type && formik.errors.type && (
                      <div>
                        <p className="field-error">{formik.errors.type}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xs-12 mt-3">
                  <div className="vehicleNoWrapper">
                    <p className="documentTypeLabel">Vehicle No</p>
                    <Select
                      options={vehicleOption}
                      styles={customStyles}
                      defaultValue={vehicleOption[0]}
                      onChange={(e) => handleVehicleNo(e)}
                      onBlur={(e) => formik.handleBlur(e)}
                      value={vehicle}
                    />
                    {formik.touched.vehicleNo && formik.errors.vehicleNo && (
                      <div className="field-error">
                        {formik.errors.vehicleNo}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xs-12 mt-3">
                  <div className="documentNoWrapper">
                    <p className="documentTypeLabel">Document No</p>
                    <input
                      placeholder="Enter Document No"
                      className="inputVehicleNo"
                      onChange={(e) =>
                        formik.setFieldValue("documentNo", e.target.value)
                      }
                      value={formik.values.documentNo}
                      onBlur={(e) => formik.handleBlur(e)}
                    />
                    {formik.touched.documentNo && formik.errors.documentNo && (
                      <div className="field-error">
                        {formik.errors.documentNo}
                      </div>
                    )}
                  </div>
                </div>
                {formik.values.type == "insurance" && showCompanyInsurance ? (
                  <>
                    <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                      <div className="documentNoWrapper">
                        <p className="documentTypeLabel">
                          Insurance Company Name
                        </p>
                        <input
                          placeholder="Enter Insurance Company Name"
                          className="inputVehicleNo"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "insuranceCompanyName",
                              e.target.value
                            )
                          }
                          value={formik.values.insuranceCompanyName}
                          onBlur={(e) => formik.handleBlur(e)}
                        />
                        {formik.touched.insuranceCompanyName &&
                          formik.errors.insuranceCompanyName && (
                            <div className="field-error">
                              {formik.errors.insuranceCompanyName}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                  <div className="documentImgWrapper">
                    <p className="documentTypeLabel">Image</p>
                    <input
                      type="file"
                      placeholder="Browse"
                      onChange={(e) =>
                        formik.setFieldValue("documentImg", e.target.files[0])
                      }
                      onBlur={(e) => formik.handleBlur(e)}
                    />
                    {formik.touched.documentImg &&
                      formik.errors.documentImg && (
                        <div className="field-error">
                          {formik.errors.documentImg}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                  <div className="expirationDateWrapper">
                    <p className="documentTypeLabel">Expiration Date</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => handleDateType(date)}
                      minDate={Date.now()}
                      onBlur={(e) => formik.handleBlur(e)}
                    />
                    {formik.touched.expirationDate &&
                      formik.errors.expirationDate && (
                        <div className="field-error">
                          {formik.errors.expirationDate}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="documentModalBtnWrapper">
                <div className="documentModalCancelBtn">
                  <a onClick={() => handleCloseDocument()}> Cancel </a>
                </div>
                <div className="documentModalSaveBtn">
                  <a onClick={() => formik.handleSubmit()}> Save ⮕ </a>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Documents;
