import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import SVG from "react-inlinesvg";
import View from "../assets/emergency_services/view.svg";
import { BsFuelPump } from "react-icons/bs";
import Fuelicon from "../assets/images/Fuelicon.png";
import FuelThefticon from "../assets/images/FuelThefticon.png";
// import { FaLocationDot } from "react-icons/fa6";
// import { Link } from "react-router-dom";

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: window.innerWidth < 1080 ? "480px" : "450px",
};

export default function MapFuelFill(props) {
  const { locations1, selectVehicleNo } = props;
  const [mapType, setMapType] = useState("roadmap");
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
  const [zoom, setZoom] = useState(10);
  const mapRef = useRef(null);

  useEffect(() => {
    // Find the index of the selected vehicle based on its vehicle number
    const selectedIndex = locations1.findIndex(
      (markerData) => markerData.vehicleNo === selectVehicleNo
    );
    setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
  }, [selectVehicleNo, locations1]);

  useEffect(() => {
    if (selectedMarkerIndex !== null && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      if (
        locations1[selectedMarkerIndex]?.lat &&
        locations1[selectedMarkerIndex]?.lng
      ) {
        bounds.extend(
          new window.google.maps.LatLng(
            locations1[selectedMarkerIndex].lat,
            locations1[selectedMarkerIndex].lng
          )
        );
        mapRef.current.fitBounds(bounds);
      }
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      if (locations1[0]?.lat && locations1[0]?.lng && mapRef.current) {
        bounds.extend(
          new window.google.maps.LatLng(locations1[0].lat, locations1[0].lng)
        );
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(10);
      }
    }
  }, [selectedMarkerIndex, locations1]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const toggleMapType = (type) => {
    if (type == "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      setMapType(newMapType); // Update mapType state
    }
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ["roadmap", "satellite"],
      style: window.google.maps.TrafficStyle,
    },
  };

  const handleToggleInfoWindow = (index) => {
    setSelectedMarkerIndex(index);
  };

  const fuelPumpIcon = (
    <svg fill="none" viewBox="0 0 16 16" height="1em" width="1em">
      <path d="M3 2.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5z" />
      <path d="M1 2a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 012 2v.5a.5.5 0 001 0V8h-.5a.5.5 0 01-.5-.5V4.375a.5.5 0 01.5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 00-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 010-1c.564 0 1.034.11 1.412.336.383.228.634.551.794.907.295.655.294 1.465.294 2.081v3.175a.5.5 0 01-.5.501H15v4.5a1.5 1.5 0 01-3 0V12a1 1 0 00-1-1v4h.5a.5.5 0 010 1H.5a.5.5 0 010-1H1V2zm9 0a1 1 0 00-1-1H3a1 1 0 00-1 1v13h8V2z" />
    </svg>
  );

  return (
    <div>
      <div className="google-map-parent">
        <GoogleMap
          onLoad={(map) => (mapRef.current = map)}
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          center={{
            lat: locations1[0]?.lat || 23,
            lng: locations1[0]?.lng || 73,
          }}
          options={mapOptions}
        >
          <MarkerClusterer>
            {(clusterer) =>
              locations1.map((markerData, index) => {
                // const icon = markerData.fuelTheft > 0 ? FuelThefticon: Fuelicon;
                return (
                  <Marker
                    key={markerData?.vehicleNo}
                    // icon={{
                    //     url: "",
                    //     scaledSize: { width: 50, height: 50 },
                    //   scale: 7
                    // }
                    // }
                    position={{ lat: markerData.lat, lng: markerData.lng }}
                    clusterer={clusterer}
                    onClick={() => handleToggleInfoWindow(index)}
                  >
                    {selectedMarkerIndex !== "" &&
                      selectedMarkerIndex == index && (
                        <InfoWindow
                          onCloseClick={() => {
                            handleToggleInfoWindow("");
                            mapRef.current.setZoom(10);
                          }} // Close info window on click outside
                        >
                          <div
                            style={{
                              color: "#fff",
                              width: "220px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginTop: "20px",
                            }}
                            className="stops"
                          >
                            {/* <Link to={`/vehicles/${markerData.vehicleNo}`} state={{ id: markerData.id }} className="text-white font-600"> */}
                            <p className="mb-2">{markerData.address}</p>
                            {/* </Link> */}
                            <p className="mb-2 text-left">
                              FuelFill:{markerData.FuelConsumed}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                  </Marker>
                );
              })
            }
          </MarkerClusterer>
        </GoogleMap>
      </div>
      <div className="map-custom-icons-fuelfill">
        <div
          className={
            mapType == "roadmap"
              ? `image-container mt-3 mb-2`
              : `image-container-active mt-3 mb-2`
          }
          onClick={() => toggleMapType("view-type")}
        >
          <SVG src={View} width={"20px"} />
        </div>
      </div>
    </div>
  );
}
