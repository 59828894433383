import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CustomTooltip = ({ id, content, place = "top", variant = "default", visible }) => (
    <ReactTooltip id={id} place={place} variant={variant} effect="solid" className='dark-tooltip' isOpen={visible}>
        {content}
    </ReactTooltip>
);

export default CustomTooltip ;
