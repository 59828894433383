import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { MdShareLocation } from "react-icons/md";
import fuelIcon from "../assets/vehicles/fuelIcon.png";
import emergencyServiceIcon from "../assets/vehicles/emergencyServiceIcon.png";
import ignitionIcon from "../assets/vehicles/ignitionIcon.png";
import mapIcon from "../assets/vehicles/mapIcon.png";
import Translate from "./Translate";
import { Link } from "react-router-dom";
import { VscDebugDisconnect } from "react-icons/vsc";

const VehicleElement = ({
  _id,
  ignitionStatus,
  speed,
  address,
  totalKmTravelled,
  fuelLevel,
  routeHistoryKm,
  vehicleNo,
  coordinates,
  isFuel,
  ignition,
  lastRecordTime,
  wireCut,
  subscirptionDaysLeft,
}) => {
  const [ignitionStatusClass, setIgnitionStatusClass] = useState("");
  const [ignitionText, setIgnitionText] = useState("");

  const date = new Date(lastRecordTime);
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

  let handleAddressLength = (address) => {
    if (address.length > 121) {
      return String(address).slice(0, 118) + "...";
    } else {
      return address;
    }
  };

  let handleIgnitionStatus = (ignitionStatus) => {
    switch (ignitionStatus) {
      case "idle":
        setIgnitionText("idle");
        setIgnitionStatusClass("vehicleStatusIdle");
        break;
      case "moving":
        setIgnitionText("moving");
        setIgnitionStatusClass("vehicleStatus");
        break;
      case "offline":
        setIgnitionText("offline");
        setIgnitionStatusClass("vehicleStatusStopped");
        break;
      default:
        setIgnitionText("offline");
        setIgnitionStatusClass("vehicleStatusStopped");
        break;
    }
  };

  useEffect(() => {
    handleIgnitionStatus(ignitionStatus);
  }, [ignitionStatus]);

  return (
    <>
      <div
        className={`vehicleComponent ${
          subscirptionDaysLeft <= 0 ? "plan-expired" : ""
        }`}
      >
        <div className="vehicleComponentHeader">
          <p className="vehicleNo">{vehicleNo}</p>
          <div className="vehicleComponentHeaderLeft">
            {ignitionStatus === "moving" ? (
              <></>
            ) : (
              <Link
                to={"/geofence/create"}
                state={{
                  coordinates: coordinates,
                  vehicleId: _id,
                  vehicleNo: vehicleNo,
                }}
                className="btn-geo"
              >
                <MdShareLocation size={20} />
              </Link>
            )}

            <div className={ignitionStatusClass}>
              <p className="vehicleStatusText">
                <Translate>{ignitionText}</Translate>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="vehicleLastRecordTime">{formattedDate}</p>
          {wireCut ? (
            <div
              style={{
                backgroundColor: "#fc6767",
                padding: "2px",
                borderRadius: "6px",
                justifyContent: "space-between",
                alignItems: "center",
                paddingInline: "4px",
              }}
            >
              <p
                className="vehicleStatusText"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <VscDebugDisconnect
                  color="white"
                  size={"14px"}
                  style={{ marginRight: "5px" }}
                />
                <span style={{ fontWeight: "500" }}>Wirecut</span>
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <p className="vehicleAddress">{handleAddressLength(address)}</p>
        <div>
          <p className="vehicleTravelledToday">
            <span>
              {totalKmTravelled} km <Translate>travelledToday</Translate>
            </span>
            {subscirptionDaysLeft <= 15 && (
              <>
                <span className="plan-expire-soon">
                  Expires in {subscirptionDaysLeft} days!
                </span>
                <Link
                  to={isFuel ? "/plans/buy-gps-fuel" : "/plans/buy-gps"}
                  state={{ vehicleNo: vehicleNo, type: isFuel ? "FUEL_RENEWAL" : "GPS_RENEWAL" }}
                  className="plan-upgrade"
                >
                  <span>Upgrade Now</span>
                </Link>
              </>
            )}
          </p>
        </div>

        <hr></hr>

        <div className="vehicleDetailsWrapper">
          <p className="vehicleDetailsHeading">
            <Translate>summary</Translate>
          </p>

          <div className="vehicleDetails">
            <div className="vehicleBoxWrapper">
              <div
                className={`vehicleDetialBox ${!isFuel ? "grayed-out" : ""}`}
              >
                {/* <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>fuelLevel</Translate>
                  </p> */}
                {/* {isFuel ? (
                  <p className="vehicleDetailValue">{fuelLevel} Ltr</p>
                ) : (
                  <p className="vehicleDetailValue unavailable">
                    notAvailable
                  </p>
                )} */}
                {/* <p className="vehicleDetailValue">{fuelLevel} Ltr</p> */}
                {/* </div>
                <div className="iconPng">
                  <img src={fuelIcon} className="vehicleIcon" />
                </div> */}
                <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>fuelLevel</Translate>
                  </p>
                  <p className="vehicleDetailValue">
                    {isFuel ? `${fuelLevel} Ltr` : "Not Available"}
                  </p>
                </div>
                <div className="iconPng">
                  <img src={fuelIcon} className="vehicleIcon" />
                </div>
              </div>
              <div className="vehicleDetialBox">
                <div className="iconPng">
                  <img src={mapIcon} className="vehicleIcon" />
                </div>
                <div className="details">
                  <p
                    className="vehicleDetailKey"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>routeHistory</Translate>
                  </p>
                  <p
                    className="vehicleDetailValue"
                    style={{ textAlign: "right" }}
                  >
                    {routeHistoryKm} Kms
                  </p>
                </div>
              </div>
            </div>
            <div className="vehicleBoxWrapper">
              <div className="vehicleDetialBox">
                <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>ignition</Translate>
                  </p>
                  <p className="vehicleDetailValue">
                    {ignition ? "ON" : "OFF"}
                  </p>
                </div>
                <div className="iconPng">
                  <img src={ignitionIcon} className="vehicleIcon" />
                </div>
              </div>
              <div className="vehicleDetialBox">
                <div className="iconPng">
                  <img src={emergencyServiceIcon} className="vehicleIcon" />
                </div>
                <div className="details">
                  <p
                    className="vehicleDetailKey"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>emergency</Translate>
                  </p>
                  <p
                    className="vehicleDetailValue"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>services</Translate>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {subscirptionDaysLeft <= 0 && (
        <div class="alert alert-danger plan-expired-alert" role="alert">
          <div className="d-flex justify-content-between">
            <span className="ml-2 font-13 plan-expire-soon mt-1">Expired</span>
            <Link
              to={isFuel ? "/plans/buy-gps-fuel" : "/plans/buy-gps"}
              state={{ vehicleNo: vehicleNo, type: isFuel ? "FUEL_RENEWAL" : "GPS_RENEWAL" }}
              className="plan-upgrade mr-10 font-13 mt-1"
            >
              <span>Upgrade Now</span>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default VehicleElement;
