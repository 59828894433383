import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { post } from "../../components/api";
import { SweetAlert, getUserIdFromLC } from "../../helpers/helper";
import Modal from "react-bootstrap/Modal";
import Translate from "../../components/Translate";
import { Trans } from "react-i18next";
 
const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "New password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords not match")
    .required("Confirm password is required"),
});
 
const ReusableModal = ({
  type,
  show,
  handleClose,
  title,
  label,
  inputValue,
  setInputValue,
  checkboxChecked,
  handleCheckboxChange,
  checkboxLabel,
  handleSave,
  disableInput = false,
  showCheckbox = false,
}) => {
  return (
    <Modal show={show} centered backdrop="static" onHide={() => handleClose(type)}>
      <Modal.Header closeButton>
        <Modal.Title className="reportModalTitle">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="section mt-2">
            <div className="text-left">
              <label htmlFor={`input-field`} className="required n-title">
                {label}
              </label>
              <input
                type="text"
                id={`input-field`}
                name={`input-field`}
                className="form-control form-control-solid mb-3 mb-lg-0"
                value={inputValue}
                disabled={disableInput}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            {showCheckbox && (
              <div className="text-left d-flex align-items-center mt-2">
                <input
                  type="checkbox"
                  checked={checkboxChecked}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                  id="flexCheckDefault"
                  className="custom-checkbox"
                />
                <p className="mb-0 n-title">{checkboxLabel}</p>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="documentModalBtnWrapper">
          <div className="documentModalCancelBtn">
            <a onClick={handleClose}> Cancel </a>
          </div>
          <div className="documentModalSaveBtn">
            <a onClick={handleSave}> Save ⮕ </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
 
const Setting = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showOverSpeedModal, setShowOverSpeedModal] = useState(false);
  const [showLowFuelModal, setShowLowFuelModal] = useState(false);
  const [showIsIdleModal, setShowIsIdleModal] = useState(false);
  const [ignitionOnOff, setIgnitionOnOff] = useState(false);
  const [lowFuel, setLowFuel] = useState(false);
  const [lowFuelvalue, setLowFuelValue] = useState(10);
  const [overSpeed, setOverSpeed] = useState(false);
  const [overSpeedLimitDefaultCheck, setOverSpeedLimitDefaultCheck] =
    useState(false);
  const [overSpeedDefaultValue, setOverSpeedDefaultValue] = useState(40);
  const [disableOverSpeedValue, setDisableOverSpeedValue] = useState(false);
  const [isFuelDrop, setIsFuelDrop] = useState(false);
  const [isLowNetwork, setIsLowNetwork] = useState(false);
  const [isGeofence, setIsGeofence] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [idleValue, setIdleValue] = useState('');
 
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    // vehicle notifications
    vehicleNotification: false,
  };
  // form
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const updatePass = await post("/admin/account-settings/change-password", {
        comapnyId: getUserIdFromLC(),
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      if (updatePass.status == 200) {
        SweetAlert("Password Changed Successfully", false);
        formik.handleReset();
      } else {
        SweetAlert("Unable to change the password, Try again", true);
        formik.handleReset();
      }
    },
  });
 
  const togglePasswordVisibility = (type) => {
    if (type == "current") {
      setPasswordVisible(!passwordVisible);
    } else if (type == "new") {
      setNewPasswordVisible(!newPasswordVisible);
    } else if ("confirm") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };
 
  const handleModal = (type, isChecked) => {
    if (type === "lowFuel") {
      setLowFuel(isChecked);
      if (isChecked) {
        setShowLowFuelModal(true);
      }
    } else if (type === "overSpeed") {
      setOverSpeed(isChecked);
      if (isChecked) {
        setShowOverSpeedModal(true);
      }
    } else if (type === 'isIdle') {
      setIsIdle(isChecked);
      if (isChecked == true) {
        setShowIsIdleModal(true);
      }
    }
  };
 
  const handleCloseModal = (type) => {
    console.log("type", type);
    if (type === "lowFuel") {
      setShowLowFuelModal(false);
      setLowFuel(false);
    } else if (type === "overSpeed") {
      setShowOverSpeedModal(false);
      setOverSpeed(false);
    } else if (type === "isIdle") {
      setShowIsIdleModal(false);
      setIsIdle(false);
    }
  };
 
  const handleOverSpeedDefaultValue = (isChecked) => {
    if (isChecked) {
      setOverSpeedDefaultValue(40);
      setDisableOverSpeedValue(true);
      setOverSpeedLimitDefaultCheck(isChecked);
    } else {
      setDisableOverSpeedValue(false);
      setOverSpeedLimitDefaultCheck(isChecked);
    }
  };
 
  const handleApiCall = async (type) => {
    let jsonData = {
      ignationAlertStatus: ignitionOnOff,
      isFuelDrop: isFuelDrop,
      isLowNetwork: isLowNetwork,
      isGeofence: isGeofence,
      companyId: getUserIdFromLC(),
      isLowFuel: lowFuel,
      lowFuelvalue: lowFuelvalue,
      overSpeedAlertStatus: overSpeed,
      speedLimit: overSpeedDefaultValue,
      isIdle: isIdle,
      idleValue: idleValue
    };
    const apiCall = await post(
      "/admin/account-settings/notification",
      jsonData
    );
    console.log("apiCall", apiCall);
    if (apiCall.status == 200) {
      SweetAlert("Settings updated successfully", false);
      fetchState();
    } else {
      SweetAlert("Unable to update the setting, Try again", true);
    }
    handleCloseModal(type);
  };
 
  const fetchState = async () => {
    const getUpdatedState = await post("/getNotificationSetting", {});
    if (getUpdatedState.status == 200) {
      const {
        ignationAlertStatus,
        overSpeedAlertStatus,
        isFuelDrop,
        isGeofence,
        isLowFuel,
        isLowNetwork,
        speedLimit,
        lowFuelvalue,
        isIdle,
        idleValue
      } = getUpdatedState.data || {};
     
      setIgnitionOnOff(ignationAlertStatus);
      setOverSpeed(overSpeedAlertStatus);
      setIsFuelDrop(isFuelDrop);
      setIsGeofence(isGeofence);
      setLowFuel(isLowFuel);
      setIsLowNetwork(isLowNetwork);
      setIsIdle(isIdle);
      if (overSpeedAlertStatus) {
        setOverSpeedDefaultValue(speedLimit);
      }
      if (isLowFuel) {
        setLowFuelValue(lowFuelvalue);
      }
      if (isIdle) {
        setIdleValue(idleValue);
      }
    }
  };
 
  const handleSettingsApiCall = async () => {
    let body = {
      ignationAlertStatus: ignitionOnOff,
      isFuelDrop: isFuelDrop,
      isLowNetwork: isLowNetwork,
      isGeofence: isGeofence,
      companyId: getUserIdFromLC(),
    };
 
    if (overSpeed) {
      body.overSpeedAlertStatus = overSpeed;
      body.speedLimit = parseInt(overSpeedDefaultValue);
    } else {
      body.overSpeedAlertStatus = overSpeed;
    }
 
    if (lowFuel) {
      body.isLowFuel = lowFuel;
      body.fuel = parseInt(lowFuelvalue);
    } else {
      body.isLowFuel = lowFuel;
    }
 
    if (isIdle) {
      body.isIdle = isIdle;
      body.idleValue = parseInt(idleValue);
    } else {
      body.isIdle = isIdle;
    }
 
    const apiResponse = await post(
      "/admin/account-settings/notification",
      body
    );
    if (apiResponse.status == 200) {
      SweetAlert("Settings updated successfully", false);
      fetchState();
    } else {
      SweetAlert("Unable to update the notification setting", true);
      fetchState();
    }
  };
 
  useEffect(() => {
    fetchState();
  }, []);
 
  return (
    <>
      {showOverSpeedModal && (
        <ReusableModal
          type={"overSpeed"}
          show={showOverSpeedModal}
          handleClose={handleCloseModal}
          title="Update Speed Limit"
          label="Enter Speed Limit"
          inputValue={overSpeedDefaultValue}
          setInputValue={setOverSpeedDefaultValue}
          checkboxChecked={overSpeedLimitDefaultCheck}
          handleCheckboxChange={handleOverSpeedDefaultValue}
          checkboxLabel="Set this Limit as Default"
          handleSave={() => handleApiCall("overSpeed")}
          disableInput={disableOverSpeedValue}
          showCheckbox={true}
        />
      )}
      {showLowFuelModal && (
        <ReusableModal
          type={"lowFuel"}
          show={showLowFuelModal}
          handleClose={handleCloseModal}
          title="Update Fuel Value"
          label="Enter Minimum Fuel"
          inputValue={lowFuelvalue}
          setInputValue={setLowFuelValue}
          handleSave={() => handleApiCall("lowFuel")}
          showCheckbox={false} // No checkbox for this modal
        />
      )}
      {showIsIdleModal && (
        <ReusableModal
          type={"isIdle"}
          show={showIsIdleModal}
          handleClose={handleCloseModal}
          title="Update Idle Time"
          label="Enter Minimum Idle Time"
          inputValue={idleValue}
          setInputValue={setIdleValue}
          handleSave={() => handleApiCall("isIdle")}
          showCheckbox={false} // No checkbox for this modal
        />
      )}
      <div className="settings">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xs-12">
            <div class="card custom-card" style={{ height: "100%" }}>
              <div className="card-body">
                <h6 className="text-left"><Translate>Change Password</Translate></h6>
                <form style={{ height: "95%" }} onSubmit={formik.handleSubmit}>
                  <div className="row p-3">
                    <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                      <label
                        htmlFor={`current-password`}
                        className="required custom-form-label"
                      >
                        <Translate>Current Password</Translate>
                      </label>
                      <div class="input-group">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="currentPassword"
                          name="currentPassword"
                          className={`form-control`}
                          value={formik.values.currentPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          className="input-group-text"
                          onClick={() => togglePasswordVisibility("current")}
                          style={{ cursor: "pointer" }}
                        >
                          {passwordVisible ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </span>
                      </div>
                      {formik.errors.currentPassword &&
                        formik.touched.currentPassword && (
                          <div className="field-error">
                            {formik.errors.currentPassword}
                          </div>
                        )}
                    </div>
                    <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                      <label
                        htmlFor={`new-password`}
                        className="required custom-form-label"
                      >
                        <Translate>newPassword</Translate>
                      </label>
                      <div class="input-group">
                        <input
                          type={newPasswordVisible ? "text" : "password"}
                          id="newPassword"
                          name="newPassword"
                          className={`form-control`}
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          className="input-group-text"
                          onClick={() => togglePasswordVisibility("new")}
                          style={{ cursor: "pointer" }}
                        >
                          {newPasswordVisible ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </span>
                      </div>
                      {formik.errors.newPassword &&
                        formik.touched.newPassword && (
                          <div className="field-error">
                            {formik.errors.newPassword}
                          </div>
                        )}
                    </div>
                    <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                      <label
                        htmlFor={`confirm-password`}
                        className="required custom-form-label"
                      >
                       <Translate>confirmNewPassword</Translate> 
                      </label>
                      <div class="input-group">
                        <input
                          type={confirmPasswordVisible ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          className={`form-control`}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          className="input-group-text"
                          onClick={() => togglePasswordVisibility("confirm")}
                          style={{ cursor: "pointer" }}
                        >
                          {confirmPasswordVisible ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </span>
                      </div>
                      {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword && (
                          <div className="field-error">
                            {formik.errors.confirmPassword}
                          </div>
                        )}
                    </div>
                    <div
                      className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-end flex-column"
                      style={{ "margin-top": "2rem" }}
                    >
                      <Button
                        type="submit"
                        className="btn custom-submit-btn ml-20"
                      >
                        Save Changes
                      </Button>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xs-12">
            <div class="card custom-card">
              <div className="card-body">
                <div>
                  <table class="table table-hover">
                    <thead>
                      <tr className="text-left">
                        <th scope="col" className="th-heading">
                          <Translate>Notification Event</Translate>
                        </th>
                        {/* <th scope="col" className="th-heading">Email</th> */}
                        <th scope="col" className="th-heading">
                          Push
                        </th>
                      </tr>
                    </thead>
                    <tbody className="notifications">
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title">Ignition On/Off</p>
                          <p className="mb-0 details">
                            <Translate>Whenever ignition turns off.</Translate>
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={ignitionOnOff}
                            id="flexCheckDefault"
                            className="custom-checkbox"
                            onChange={(e) => setIgnitionOnOff(e.target.checked)}
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title"><Translate>Low Fuel</Translate></p>
                          <p className="mb-0 details">
                            {`Whenever fuel goes below ${lowFuelvalue} ltr.`}
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={lowFuel}
                            onChange={(e) =>
                              handleModal('lowFuel', e.target.checked)
                            }
                            id="flexCheckDefault"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title">OverSpeed</p>
                          <p className="mb-0 details">
                          <Translate>Whenever truck is in overspeeding.</Translate>
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={overSpeed}
                            onChange={(e) =>
                              handleModal('overSpeed', e.target.checked)
                            }
                            id="flexCheckOverSpeed"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title">Idle Time</p>
                          <p className="mb-0 details">
                          Whenever truck is Idle in selected timeperiod.
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={isIdle}
                            onChange={(e) =>
                              handleModal('isIdle', e.target.checked)
                            }
                            id="flexCheckOverSpeed"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title"><Translate>Sudden fuel dropped</Translate></p>
                          <p className="mb-0 details">
                          <Translate>Whenever fuel level drops suddenly</Translate>
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={isFuelDrop}
                            onChange={(e) => setIsFuelDrop(e.target.checked)}
                            id="flexCheckDefault"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title">Low Network</p>
                          <p className="mb-0 details">
                            Whenever network strength is low
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={isLowNetwork}
                            onChange={(e) => setIsLowNetwork(e.target.checked)}
                            id="flexCheckDefault"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                      <tr className="text-left">
                        <td>
                          <p className="mb-1 title">Geofence</p>
                          <p className="mb-0 details">
                            Whenever vehicles inside geofence
                          </p>
                        </td>
                        <td className="p-checkbox">
                          <input
                            type="checkbox"
                            checked={isGeofence}
                            onChange={(e) => setIsGeofence(e.target.checked)}
                            id="flexCheckDefault"
                            className="custom-checkbox"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-end flex-column">
                  <Button
                    type="button"
                    onClick={handleSettingsApiCall}
                    className="btn custom-submit-btn ml-20"
                  >
                    Save Changes
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Setting;