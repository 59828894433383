import React, { useState, useEffect } from "react";
import MapComponent from "../../components/ShareMap";
import { useParams } from "react-router-dom";
import { post } from "../../components/api";
// import config from "../../components/gMap/config";
import moment from "moment";
import StopHistory from "../../components/StopHistory";
import Loading from "../../components/Loading";
import { SweetAlert } from "../../helpers/helper";
import { RxCross1 } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";

const SharePage = () => {
  let { id } = useParams();
  let [location, setLocation] = useState([]);
  let [vehicleNo, setVehicleNo] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  const [stopData, setStopData] = useState([]);
  const [isRouteHistoryVisible, setIsRouteHistoryVisible] = useState(false);
  console.log(id);
  // Function to toggle the Route History panel
  const toggleRouteHistory = () => {
    setIsRouteHistoryVisible(!isRouteHistoryVisible);
  };
  const fetchStopData = async (vehicleNo) => {
    console.log("1");
    try {
      const getData = await post("/get-stops", {
        vehicleNo: vehicleNo,
        timeFilter: "range",
        endDate: moment().endOf("day"),
        startDate: moment().startOf("day"),
      });
      console.log("getdata ", getData.data);
      if (getData.data) {
        setStopData(getData.data);
      } else {
        setStopData([]);
      }
    } catch (error) {
      console.log("error", error);
      setStopData([]);
    }
  };
  const getVehicleDataByToken = async (id) => {
    setIsLoading(true);
    const getVehicle = await post("/get-vehicle-detials", { token: id });
    if (getVehicle.status === 200) {
      let locationData = getVehicle.data.locationData;
      const locations = [];
      locationData.forEach((loc, i) => {
        let locData = {
          lat: loc.coordinates[0],
          lng: loc.coordinates[1],
          timeStamp: moment(loc.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          fuel: loc.fuel ? loc.fuel : "",
          ignition: loc.ignition,
          speed: loc.speed,
          address: loc.address,
        };
        locations.push(locData);
      });
      setLocation(locations);
      console.log("location ", locations);
      setVehicleNo(getVehicle.data.vehicleNo);
      await fetchStopData(getVehicle.data.vehicleNo);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      SweetAlert("Something went wrong in fetching data", true);
    }
  };

  useEffect(() => {
    console.log("repeat");
    getVehicleDataByToken(id);
  }, []);

  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10rem",
      }}
    >
      <Loading />
    </div>
  ) : (
    <>
      {location.length > 0 ? (
        <div
          className="map-details-wrapper"
          style={{ position: "relative", height: "100vh" }}
        >
          {/* Hamburger Menu Button */}
          {!isRouteHistoryVisible && (
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                width: "40px",
                height: "40px",
                backgroundColor: "#ffffff",
                border: "1px solid #ddd",
                borderRadius: "50%",
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={toggleRouteHistory}
            >
              <GiHamburgerMenu style={{ color: "#007bff" }} />
            </div>
          )}

          {/* Sidebar with Stop History */}
          <div
            className="sidebar"
            style={{
              position: "absolute",
              top: 0,
              right: isRouteHistoryVisible ? 0 : "-350px",
              height: "100%",
              width: "300px",
              backgroundColor: "#ffffff",
              boxShadow: "2px 0 5px rgba(0,0,0,0.2)",
              zIndex: 9998,
              transition: "right 0.3s ease-in-out",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <h2 style={{ marginTop: "10px", fontSize: "16px" }}>
                  Route History
                </h2>
                <RxCross1
                  style={{ margin: "10px", cursor: "pointer" }}
                  onClick={toggleRouteHistory}
                />
              </div>

              <div
                style={{
                  padding: "0.7rem",
                  overflowY: "auto",
                  height: "calc(100% - 60px)",
                }}
              >
                {/* Content of the sidebar, e.g., stopped vehicle history */}
                <StopHistory stopData={stopData} />
              </div>
            </div>
          </div>

          {/* Map Container */}
          <div
            className="map-wrapper"
            style={{
              marginRight: isRouteHistoryVisible ? "300px" : "0px",
              transition: "margin-right 0.3s ease-in-out",
              height: "100vh",
              width: isRouteHistoryVisible ? "calc(100% - 300px)" : "100%",
            }}
          >
            <MapComponent
              locationData={location}
              vehicleNo={vehicleNo}
              stopData={stopData}
            />
          </div>
        </div>
      ) : (
        <p>No location data found!</p>
      )}
    </>
  );
};

export default SharePage;
