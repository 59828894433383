import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { IoMdClose } from "react-icons/io";
import { Button } from "react-bootstrap";
import { SweetAlert } from "../helpers/helper";
import Loading from "../components/Loading"
import Stopped from "../assets/images/stopped.png";
import Running from "../assets/images/running.png";

const Drawer = ({ show, closeDrawer, data, timeFilter, historyDataFilter, isLoading, historyDataCustomRange, totalRunTime, stopTime }) => {
  const daterangepickerRef = useRef(null);
  const [historyType, setHistoryType] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [startDate, setStart] = useState(moment().toDate());
  const [endDate, setEnd] = useState(moment().toDate());
  const today = moment().endOf('day');
  const now = moment();

  useEffect(() => {
    setFilterData(data);
  }, [data])

  const drawerClasses = show ? "side-drawer open p-3" : "side-drawer";
  function truncateString(text, maxLength) {
    if (text.length > maxLength) {
      // Truncate the text to the maximum length and add ellipsis
      return text.substring(0, maxLength - 3) + "...";
    } else {
      // Return the original text if it doesn't exceed the maximum length
      return text;
    }
  }

  const openDatePicker = (type) => {
    historyDataFilter(type);
  }

  // filter route data
  const filterRouteData = (type) => {
    historyDataFilter(type);
  }
  
  const onChangeHistoryType = (type) => {
    if (type == 'stop-time') {
      const filteredRecords = data.filter(record => record.status === 'stopped');
      setFilterData(filteredRecords);
    } else {
      setFilterData(data);
    }
    setHistoryType(type);
  }

  const handleApply = (event, picker) => {
    const selectedStart = picker.startDate;
    const selectedEnd = picker.endDate;
    if (selectedEnd.isBefore(selectedStart.clone().add(1, 'months'))) {
      setStart(selectedStart);
      setEnd(selectedEnd);
      const startDateWithTime = selectedStart.toISOString();
      const endDateWithTime = selectedEnd.toISOString();
      historyDataCustomRange('range', startDateWithTime, endDateWithTime);
    } else {
      SweetAlert("Please select a date range within one month from the start date.", true);
    }
  };

  const initialSettings = {
      startDate: startDate,
      endDate: endDate,
      timePicker: true,
      timePicker24Hour: false,
      maxDate: today.toDate(),
      maxTime: now.format('hh:mm A'),
      locale: {
        format: 'MMMM D, YYYY hh:mm A', // 12-hour format
      },
      opens: 'left',
    }
  return (
    <React.Fragment>
      {show && <div className="backdrop" onClick={closeDrawer}></div>}
      {/* Drawer content */}
      <div className={drawerClasses}>
        <div className="d-flex justify-content-between">
          <h6 className="text-left">Route History</h6>
          {timeFilter === 'range' && <p>{startDate && <span>{moment(startDate).format('DD/MM/YYYY')}</span>}{endDate && <span> - {moment(endDate).format('DD/MM/YYYY')}</span>}</p>}
          <IoMdClose className="cursor-pointer" onClick={closeDrawer} />
        </div>
        <hr />
        <div className="d-flex justify-content-between mt-3">
            <Button variant={timeFilter == 'today' ? 'outline-primary primary-btn active': 'outline-primary primary-btn'}onClick={()=> filterRouteData('today')}>Today</Button>
            <Button variant={timeFilter == 'yesterday' ? 'outline-primary primary-btn active': 'outline-primary primary-btn'}onClick={()=> filterRouteData('yesterday')}>Yesterday</Button>
            <Button variant={timeFilter == '7daysrange' ? 'outline-primary primary-btn active': 'outline-primary primary-btn'}onClick={()=> filterRouteData('7daysrange')}>Last 7 days</Button>
            <DateRangePicker
              className="custom-date-range"
              ref={daterangepickerRef}
              initialSettings={initialSettings}
              onApply={handleApply}
            >
              <Button variant={timeFilter == 'range' ? 'outline-primary primary-btn active': 'outline-primary primary-btn'} onClick={()=> openDatePicker('range')}>Date Range</Button>
            </DateRangePicker>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className={`run-time-box ${historyType === 'run-time' ? 'run-time-box-active' : ''}`} onClick={() => onChangeHistoryType('run-time')}>
            <p className="mb-1 title">Run Time</p>
            <p className="mb-0 value">{totalRunTime ? totalRunTime : "0 Km (0h:0m)"}</p>
          </div>
          <div className={`stop-time-box ${historyType === 'stop-time' ? 'stop-time-box-active' : ''}`} onClick={() => onChangeHistoryType('stop-time')}>
            <p className="mb-1 title">Stop-time</p>
            <p className="mb-0 value">{stopTime ? stopTime : "0 Km (0h:0m)"}</p>
          </div>
        </div>
        <div className="route-date-picker">
        </div>
        {filterData.length > 0 ? <div className="history-horizontal-scroll"><div className="mt-4 mb-1">
          {filterData.length > 0 && filterData.map((route, i) => (
            <div className="d-flex justify-content-start align-items-start mb-2" key={`route-history-${i}`}>
                <img src={route.status == 'running' ? Running : Stopped} alt="" height={'100px'} />
                <div className="history">
                    <div className="d-flex align-items-center">
                        <p className={`mb-1 ${route.status}`}>{route.status}</p>
                        <p className="total-time mb-1">{route.time}</p>
                    </div>
                    <span className="address mb-1">{truncateString(route.address, 100)}</span>
                    <div><span className="date-time mb-1">{route.startingTime}</span> - <span className="date-time">{route.endingTime}</span></div>
                </div>
            </div>
          ))}
        </div></div>: data.length <= 0 && isLoading ? <Loading /> : <div className="mt-2">No route found!</div>}
      </div>
    </React.Fragment>
  );
};

export default Drawer;
