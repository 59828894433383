import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Button, Card } from "react-bootstrap";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  OverlayView
} from "@react-google-maps/api";
import Countdown from 'react-countdown';
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactSlider from "react-slider";
import { FaTruckMoving } from "react-icons/fa6";
import { FaPlayCircle, FaPauseCircle, FaMapMarkerAlt } from "react-icons/fa";
import { BsFuelPump } from "react-icons/bs";
import { IoSpeedometerOutline } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { SweetAlert } from "../../helpers/helper";
import Loading from "../../components/Loading";
import { post } from "../../components/api";
import ProgressBar from "../../components/ProgressBar";
import CustomTooltip from "../../components/Tooltip";
import View from "../../assets/emergency_services/view.svg";
import truck_slider from "../../assets/images/truck_slider.svg";
import stopIcon from "../../assets/vehicles/stopIcon.svg";
import idleIcon from "../../assets/vehicles/idleicon.svg";
// import fuelFillIcon from "../../assets/vehicles/fuelfillicon.svg";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const VehiclePlayRoute = () => {
  const params = useParams();
  const { state } = useLocation();
  const vehicleNo = params?.id;
  const zoomLevel = 14;
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [completedPath, setCompletedPath] = useState([]);
  const [pendingPath, setPendingPath] = useState([]);
  const [openInfoWindow, setOpenInfoWindow] = useState(true);
  const [currentPointIndex, setCurrentPointIndex] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 22.9889,
    lng: 72.498297,
  });
  const animationIntervalRef = useRef(null);
  const [mapType, setMapType] = useState("roadmap");
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [timeFilter, setTimeFilter] = useState({
    start: moment().startOf("day").toISOString(),
    end: moment().toISOString(),
  });
  const [playRoute, setPlayRoute] = useState(true);
  const [path, setPaths] = useState([]);
  const [vehicleHistory, setVehicleHistory] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const today = moment().endOf("day");
  const now = moment();
  const maxSpan = {
    days: 30,
  };
  const initialSettings = {
    startDate: moment().startOf("day").toDate(),
    endDate: moment().toDate(),
    timePicker: true,
    timePicker24Hour: false,
    maxDate: today.toDate(),
    maxTime: now.format("HH:mm"),
    maxSpan: maxSpan,
    locale: {
      format: "DD MMM YYYY", // 12-hour format
    },
    opens: "left",
    ranges: {
      Today: [moment().startOf("day"), moment()],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [moment().subtract(6, "days").startOf("day"), moment()],
      "Last 15 Days": [moment().subtract(14, "days").startOf("day"), moment()],
    },
  };

  const speedToZoom = {
    1: 14,
    2: 12,
    4: 10,
    8: 8,
  };
  
  const getRouteData = async (timeFilter) => {
    setIsLoading(true);
    try {
      let jsonData = {
        vehicleNo: vehicleNo,
        timeFilter: timeFilter,
      };
      const routeData = await post("/admin/vehicle-play-route", jsonData);
      if (routeData.status === 200) {
        const updatedData = routeData.data.data.map((stop) => {
          const [stopLat, stopLng] = stop.coordinates;
          const matchingLocationIndex = routeData.data.locations.findIndex(
            (location) => {
              return (
                stopLat === location.lat && // Check latitude
                stopLng === location.lng // Check longitude
              );
            }
          );
          if (matchingLocationIndex !== -1) {
            return { ...stop, position: matchingLocationIndex }; // Add position
          }
          return stop;
        });
        setPendingPath([]);
        setCompletedPath([]);
        setPaths(routeData?.data?.locations);
        setVehicleHistory(updatedData);
        setIsLoading(false);
      } else {
        setPaths([]);
        setIsLoading(false);
        SweetAlert(routeData.message, true);
        setTimeout(() => {
          navigate("/vehicles");
        }, 2000);
      }
    } catch (err) {
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    getRouteData(timeFilter);
  }, [timeFilter]);

  useEffect(() => {
    startAnimation();
    return () => cancelAnimation();
  }, [currentPointIndex, path, playRoute]);

  const startAnimation = () => {
    // Only start the animation if we have a valid next point
    if (playRoute && currentPointIndex < path.length - 1) {
      const nextPoint = path[currentPointIndex + 1];
      interpolateMovement(path[currentPointIndex], nextPoint);
      setPendingPath(path.slice(currentPointIndex));
  
      setCompletedPath((prev) => {
        const newPath = [...prev];
        const currentPath = path[currentPointIndex];
        if (!newPath.includes(currentPath)) {
          newPath.push(currentPath);
        } else {
          const currentIndexInPath = newPath.indexOf(currentPath);
          newPath.splice(currentIndexInPath + 1);
        }
        return newPath;
      });
  
      // Set an interval to update the index after the specified duration
      const interval = setTimeout(() => {
        setCurrentPointIndex((prevIndex) => {
          if (prevIndex < path.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }, 2000 / speedMultiplier);
  
      animationIntervalRef.current = interval;
  
      // Clean up when the effect re-runs or on unmount
      return () => cancelAnimation();
    }
  };

  // Interpolation function for smooth movement
  const interpolateMovement = async (startPoint, endPoint) => {
    const baseDuration = 5000; // Duration of movement
    const adjustedDuration = baseDuration / (speedMultiplier || 1);
    const startLat = startPoint.lat;
    const startLng = startPoint.lng;
    const endLat = endPoint.lat;
    const endLng = endPoint.lng;
    const latDiff = endLat - startLat;
    const lngDiff = endLng - startLng;
    const startTime = performance.now();
    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / adjustedDuration, 1); // Ensure progress is capped at 1
      const easedProgress = easeInOutQuad(progress);
      const currentLat = startLat + latDiff * easedProgress;
      const currentLng = startLng + lngDiff * easedProgress;
      setCurrentPosition({ lat: currentLat, lng: currentLng });
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  };

  const cancelAnimation = () => {
    // Stop any ongoing timeout-based movement
    if (animationIntervalRef.current) {
      clearTimeout(animationIntervalRef.current);
      animationIntervalRef.current = null; // Reset interval ref
    }
  };

  const handleSliderChange = (value) => {
    if (animationIntervalRef.current) {
      clearTimeout(animationIntervalRef.current);
      animationIntervalRef.current = null; // Reset interval ref
    }
    setCurrentPointIndex(value);
    setCurrentPosition({
      lat: path[value]?.lat,
      lng: path[value]?.lng,
    });
  };

  const handleSelect = (eventKey) => {
    cancelAnimation();
    const selectedSpeed = parseInt(eventKey.replace("x", ""), 10);
    const newZoom = speedToZoom[selectedSpeed];
    setSpeedMultiplier(selectedSpeed);
    if(map) {
      map.setZoom(newZoom)
    }
    setTimeout(() => {
      if (playRoute) {
        startAnimation();
      }
    }, 5000);
  };

  const toggleMapType = (type) => {
    if (type == "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      setMapType(newMapType); // Update mapType state
    }
  };

  const handleMarkerClick = () => {
    setOpenInfoWindow(!openInfoWindow);
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ["roadmap", "satellite"],
      style: window.google.maps.TrafficStyle,
    },
  };

  // change date range
  const handleApply = (event, picker) => {
    const selectedStart = picker.startDate;
    const selectedEnd = picker.endDate;
    const startDateTime = selectedStart.toISOString();
    const endDateTime = selectedEnd.toISOString();
    setTimeFilter({
      start: startDateTime,
      end: endDateTime,
    });
  };

  const countdownDate = useMemo(() => {
    if (playRoute) {
      return Date.now() + calculateRemainingTime();
    }
    return Date.now() + remainingTime;
  }, 
  [currentPointIndex, playRoute, speedMultiplier]);

  // Handler for play/pause route
  const togglePlayPause = () => {
    const remaining = countdownDate - Date.now();
    setPlayRoute((prevPlayRoute) => !prevPlayRoute);
    setRemainingTime(remaining);
    if (playRoute) {
      if (animationIntervalRef.current) {
        clearTimeout(animationIntervalRef.current);
        animationIntervalRef.current = null; // Reset interval ref
      }
    }
  };

  function calculateRemainingTime() {
    const timePerRecord = 1000 / speedMultiplier;
    const recordsLeft = path.length - currentPointIndex;
    const totalRemainingTime = recordsLeft * timePerRecord;
    const adjustedRemainingTime = totalRemainingTime - 1000;
    return adjustedRemainingTime > 0 ? adjustedRemainingTime : 0;
  };

  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <div className="countdown">
        {hours > 0
          ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
          : `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}
      </div>
    );
  };

  const center = { lat: 22.9889, lng: 72.498297 };
  const angle = path[currentPointIndex]?.angle ? path[currentPointIndex]?.angle : 180;
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Button
          variant="route-active-btn"
          onClick={() => navigate(`/vehicles/${vehicleNo}`, { state: { id: state.id } })}
        >
          Back to Vehicles
        </Button>
        <DateRangePicker
          className="custom-date-range"
          initialSettings={initialSettings}
          onApply={handleApply}
          style={{ width: "200px" }}
        >
          <input
            type="text"
            className="form-control"
            style={{ width: "200px" }}
          />
        </DateRangePicker>
      </div>
      <Card className="mt-2">
        <Card.Body>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="google-map-parent">
                <div className="google-map-parent">
                  <GoogleMap
                    onLoad={setMap}
                    zoom={zoomLevel}
                    center={currentPosition ? currentPosition : center}
                    mapContainerStyle={mapContainerStyle}
                    options={mapOptions}
                  >
                    <Polyline
                      path={pendingPath} // Pending path for visualization
                      options={{
                        strokeColor: "#7F92A4",
                        strokeWeight: 5,
                      }}
                    />
                    <Polyline
                      path={completedPath} // Completed path for the polyline
                      options={{
                        strokeColor: "#2A7C76", // Color for the completed path
                        strokeWeight: 5,
                      }}
                    />
                    {vehicleHistory.length > 0 &&
                      vehicleHistory.map((stop, index) => (
                        <Marker
                          // key={index}
                          position={{
                            lat: stop.coordinates[0],
                            lng: stop.coordinates[1],
                          }}
                          title={vehicleHistory.length - index}
                          icon={{
                            url: stop.status == "idle" ? idleIcon : stopIcon,
                            scaledSize: new window.google.maps.Size(36, 36),
                            scale: 7,
                          }}
                          zIndex={1}
                        />
                    ))}
                      <OverlayView
                        position={currentPosition}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(width, height) => ({
                          x: -(width / 2),
                          y: -(height / 2),
                        })}
                      >
                        <div
                          className="vehicle-marker"
                          style={{
                            transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                            backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAuCAYAAADDX4LFAAAACXBIWXMAAAsSAAALEgHS3X78AAAE50lEQVRYha2XTWwbRRSAv5n9iR0nsZ0qIUlTE5qkqH8kquDCAVQkWqk/3Arihji3cEOCS3NDQiCORRwQIC4QqIQEXFBVcaEHxKUhLW1D4grcJm1oYtw4jndnhkMc765/krTOk0ba2dn53pv33sy8FTSQy5cvd0jHeWVXd/rDWzMzl3L/5GzLtrCkhRQCjSGTybQP9PWP/p3Lvdve1nbp6NGjD2s5ovbF9z/98ObQniffF1L0aq1Jp1L4no/WGq0VWhu0UkjbwlMKIQQCFrJz2fdOnTjxWVP45OTk87sze35JdHTYpVKJVDJFX08PWmuUUnXNUz5r5TKO45DP5/3bs3Mvnjlz5tcNnoxocu0j7YmE7XkeWmu6UymEqFtcVWzLAgOu65JMpWynre1AeDwCt4T1EKBYLBKPx4nHYtWxDevDopWmzXXRStHmuEjL8iPKa63RWlMsFsnOzXHxm0muTk2RzWZZXl7GcRzGx8fp6++nr+8JBvoHGB8fx3VdOpNdNabWwD3Po1wu89bZs9y9c5f2RIJ4PEY8Hie9q5t4LMa/Sw9YWS2Su5Pjj7ZrXPj0E/aNjnL+/ASlUqk5XCnFw0KB4koRaVmVlRgc2yGdShGLxXEcB1/5+L6Pbfv4SnFrZoaFhQWUp5rDbdsW8/PzHD92jNF9+xgZGWFwcJDOjg6klOtpJwQGKK2ucn9xkdnZWWbnZpmenqa7e1ck+gJgYmLCzWQyHxg4eW/x/sjrr76G4zhV2FbN930+//ILent6Z7QxP96em3tnYmKivKFJDA0NJZ8+uP+N06dOf3z6xMmIpdtpX387yeR3F8/N3rjxVTabzQNmI74mm80ux9vbTTqdxhhT2ZFBM8Zs2tLpNP8VlsvZbHYZMFCTPM8deTaVSqUaTq5VVtuSXV2MHXymt2lALcuyvXIZrfUjuUQIgdKaZDKpmsILxUKuuLr6WPByuUxHZ+efTeHDT40sDg4O4nneljAgeBbQ19/PmImkeXAqTk1PncvnC28vLS0N246NQKCNBmMwlc+M0QjWYcGBVnGL8kkmk391pbo+Gjtw+ELE8rWy/8LNlZvDefLgUQ0UgMFgtEErjWXLChAQAiklUgiEkCRXksOH44dfAqJwS0ou3f+Z64XrNBO1qrDiVtPx/V0HGOsdr/arqWjZNkLIhpO2K0IILCtQXqUppdYMpuGkbYsx+L6/VgcHDKY1uIHIhRKC61btDqmogWu9A2hDxEBZO9iSCMDoJvBWxQABuxbeumt0Q5+HlvP4EjVOBg875KEQP0JsMc0B0DQLaPPK7VHo9fCwxpZEBvYGPhdyRwyXIUhdrRiWTCLD7vbdwSXta5CV890Y5kvz5Eq50AxB2Ld2swGAgcQAV+5daar8UOehGng0iJvm31bpWbc3RJQY+FzCJnX+tkXQIKA7ccwIiFhoNxgOekLgShcT2l0bt5VE4kq3TkEoEwN4I5dcfXCVka6RIFvKGuGIqrKZlZlGttfDpRR1O7TgFbi2fK3a3+r2X69nGlzQQlib/rltRwQgrYYB3YmjRWCFtmhQt0jptIwXIC2n6urqg6+UtTexF0c4AJWgRasNFVNYbuDTdS9WakcEexIZlO9XPwjBvd9f7jner7VCKYMx6z+165mi0RowBikESBBCVissS0qktBBS4HvebxvM/wF8t0dXdP18GwAAAABJRU5ErkJggg==)`,
                            width: '23px',
                            height: '46px',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            position: 'absolute',
                            cursor: 'pointer',
                            left: '50%',
                            top: '50%',
                          }}
                          onClick={() => handleMarkerClick()}
                        />
                      </OverlayView>
                      {openInfoWindow && (
                        <InfoWindow position={currentPosition} onCloseClick={() => handleMarkerClick()}>
                          <div
                            style={{
                              color: "#fff",
                              width: "400px",
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "left",
                              padding: "5px",
                              alignItems: "baseline",
                            }}
                            className="infowindow-play-route"
                          >
                            <div className="p-2">
                              <div className="d-flex mb-1">
                                <FaTruckMoving size="20px" color="#ffffff" />
                                <h6 className="ml-2 font-14">
                                  {path[currentPointIndex]?.vehicleNo}
                                </h6>
                              </div>
                              <div className="d-flex align-items-start mt-1 mb-1">
                                <div style={{ width: "15px" }}>
                                  <FaMapMarkerAlt size="20px" color="#ffffff" />
                                </div>
                                <p className="ml-3 mb-2">
                                  {path[currentPointIndex]?.address}
                                </p>
                              </div>
                              <div className="mt-1 mb-2 w-230">
                                <div className="d-flex align-items-center">
                                  <div style={{ width: "15px" }}>
                                    <BsFuelPump size="20px" color="#ffffff" />
                                  </div>
                                  <p className="ml-3 mb-0">
                                    Fuel:{" "}
                                    <b className="font-600">
                                      {path[currentPointIndex]?.fuel} L /{" "}
                                      {path[currentPointIndex]?.maxTankCapacity}{" "}
                                      L
                                    </b>
                                  </p>
                                </div>
                                <div className="mt-2 mb-2 w-200">
                                  <ProgressBar
                                    value={path[currentPointIndex]?.fuel}
                                    maxValue={
                                      path[currentPointIndex]?.maxTankCapacity
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex mt-1 mb-2 justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div style={{ width: "15px" }}>
                                    <IoSpeedometerOutline
                                      size="20px"
                                      color="#ffffff"
                                    />
                                  </div>
                                  <p className="ml-3 mb-0">
                                    {path[currentPointIndex]?.speed} KMPH
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div style={{ width: "15px" }}>
                                    <MdOutlineWatchLater
                                      size="20px"
                                      color="#ffffff"
                                    />
                                  </div>
                                  <p className="ml-3 mb-0">
                                    {path[currentPointIndex]?.timeStamp}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                  </GoogleMap>
                </div>
              </div>
              <div className="map-view-icon">
                <div
                  className={
                    mapType == "roadmap"
                      ? `image-container mt-3 mb-2`
                      : `image-container-active mt-3 mb-2`
                  }
                  onClick={() => toggleMapType("view-type")}
                >
                  <SVG src={View} width={"20px"} />
                </div>
              </div>
              <div className="slider-main">
                <div className="slider-parent">
                  <div onClick={togglePlayPause} style={{ cursor: "pointer" }}>
                    {playRoute ? (
                      <FaPauseCircle
                        style={{
                          color: "#00a3fe",
                          fontSize: "30px",
                          marginRight: "5px"
                        }}
                      />
                    ) : (
                      <FaPlayCircle
                        style={{
                          color: "#00a3fe",
                          fontSize: "30px",
                          marginRight: "5px"
                        }}
                      />
                    )}
                  </div>
                  <Dropdown onSelect={handleSelect} className="dropdown-toggle-parent">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="speed-toggle">
                      {`${speedMultiplier}x`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="speed-dropdown">
                      {Array.from({ length: 4 }, (_, i) => (
                        <Dropdown.Item key={i} eventKey={`${Math.pow(2, i)}x`}>
                          {Math.pow(2, i)}x
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {path && path.length > 0 && (
                    <ReactSlider
                      className="horizontal-slider"
                      ariaLabelledby="slider-label"
                      min={0}
                      max={path.length - 1}
                      value={currentPointIndex}
                      onChange={handleSliderChange}
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      renderThumb={(props, state) => {
                        const currentIndex = state.value;
                        const { timeStamp } = path[currentIndex];
                        return (
                          <>
                            <div
                              {...props}
                              className="example-track"
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <img
                                src={truck_slider}
                                alt="Vehicle"
                                className="thumb-image"
                                data-tooltip-id={`tooltip-${currentIndex}`}
                              />
                            </div>
                            <CustomTooltip
                              id={`tooltip-${currentIndex}`}
                              content={timeStamp} // Dynamic content from path
                              className="dark-tooltip"
                              visible={isHovered}
                            />
                            {vehicleHistory.map((stop, index) => {
                              return (
                                <div
                                  // key={index}
                                  className="icon-container"
                                  style={{
                                    position: "absolute",
                                    left: `${
                                      (stop.position / (path.length - 1)) * 100
                                    }%`,
                                    transform: "translateX(-50%)",
                                    top: 1,
                                  }}
                                >
                                  <SVG
                                    src={
                                      stop.status == "idle"
                                        ? idleIcon
                                        : stopIcon
                                    }
                                    width={28}
                                    height={28}
                                  />
                                </div>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  )}
                  <Countdown date={countdownDate} renderer={renderer} />
                </div>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default VehiclePlayRoute;
