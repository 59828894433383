import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import Select from "react-select";
import DataTablePagination from "../../components/Datatable";
import ModalPopup from "../../components/ModalPopup";
import SupportTicket from "../SupportTicket";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { IoCallOutline } from "react-icons/io5";

const Support = () => {
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [initialValues, setinitialvalues] = useState({
    search: "",
    status: "",
  });

  const supportOptions = [
    {
      _id: 1,
      name: "GPS related issue",
    },
    {
      _id: 2,
      name: "Sensor related issue",
    },
    {
      _id: 3,
      name: "Account related issue",
    },
    {
      _id: 4,
      name: "Report not generated",
    },
    {
      _id: 5,
      name: "Other issue",
    },
  ];

  const columns = [
    {
      name: "Support Id",
      selector: (row) => row.supportId,
      sortable: true,
      width: "150px",
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vehicle.vehicleNo,
      sortable: true,
      width: "200px",
    },
    {
      name: "Type",
      selector: (row) => {
        const supportType = row.supportType;
        const matchedOption = supportOptions.find(
          (option) => option._id === supportType
        );
        return matchedOption ? matchedOption.name : "Unknown";
      },
      width: "200px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.status == "open") {
          return (
            <div className="invoice-badge">
              <Badge bg="info">OPEN</Badge>
            </div>
          );
        } else if (row.status == "in-progress") {
          return (
            <div className="invoice-badge">
              <Badge bg="warning">IN-PROGRESS</Badge>
            </div>
          );
        } else if (row.status == "resolved") {
          return (
            <div className="invoice-badge">
              <Badge bg="success">RESOLVED</Badge>
            </div>
          );
        }
      },
      sortable: true,
      width: "150px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
      width: "340px",
    },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "rgba(242, 243, 244, 0.5)",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      fontSize: "0.8rem",
    }),
  };

  // show modal
  const handleShowModal = () => {
    setShowTicketModal(true);
  };

  // close modal
  const handleCloseModal = (child) => {
    if (child !== undefined) {
      setinitialvalues({
        ...initialValues,
        search: "",
        status: "",
      });
    }
    setShowTicketModal(false);
  };

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  // status selection
  const handleSelectChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      submitData();
    }, 3000);
    return () => clearTimeout(timer);
  }, [search, status]);

  const submitData = () => {
    setinitialvalues({
      ...initialValues,
      search: search,
      status: status !== "" && status !== null ? status._id : "",
    });
  };

  return (
    <div className="support-page plrb-4">
      <div className="row mt-2">
        <div className="col-md-12 col-lg-12 col-xs-12 text-end mb-3">
          <button
            className="btn btn-primary custom-submit-btn"
            style={{ width: "130px", height: "40px", marginRight: "10px" }}
            onClick={() => handleShowModal(true)}
          >
            Raise Ticket
          </button>
          <OverlayTrigger
            overlay={
              <Tooltip id={`products-delete-tooltip-${0}`}>
                <span>FleetStakes</span> <br /> +919725405366
              </Tooltip>
            }
          >
            <button
              className="btn btn-secondary custom-submit-btn"
              style={{ width: "130px", height: "40px" }}
            >
              Contact us
            </button>
          </OverlayTrigger>
        </div>
        <div className="col-md-8 col-lg-8 support-box">
          <div className="card custom-card-setting" style={{ height: "100%" }}>
            <div className="card-body">
              <form>
                <div className="row support-page-form">
                  <div className="col-md-6 col-lg-6 col-xs-12 text-left">
                    <label className="required custom-lable-support mb-2">
                      Select Ticket ID/Vehicle no.
                    </label>
                    <input
                      placeholder="Select Ticket ID/Vehicle no."
                      type="text"
                      name="search"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      autoComplete="off"
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 text-left">
                    <label className="custom-lable-support mb-2">
                      Select Status
                    </label>
                    <Select
                      value={status}
                      options={supportOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handleSelectChange}
                      // defaultValue={supportOptions[0]}
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                  {/* <div className="col-md-7 col-lg-2 col-xs-12 mt-3">
                    <button
                        type="button"
                        onClick={submitData}
                        className="btn btn-primary custom-submit-btn mb-0"
                        style={{ width: "80px", height: "40px" }}
                        >Search</button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4 col-lg-4 support-box">
          <div className="card custom-card-setting" style={{ height: "100%" }}>
            <div className="card-body" style={{ height: "100%" }}>
              <div className="main-div-contact" style={{ height: "100%" }}>
                <div className="child-div-contact col-md-5 col-lg-4 col-xs-12">
                  <IoCallOutline
                    className="support-icon"
                    style={{ fontSize: "1em" }}
                  />
                  <p className="mb-0 support-name" style={{ color: "#2A7C76" }}>
                    FleetStakes
                  </p>
                  <p
                    className="mb-0 support-number"
                    style={{ paddingLeft: "5px" }}
                  >
                    <u>+91 9725405366</u>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row mt-5">
        <div className="col-md-12 col-lg-12 col-xs-12">
          <div className="custom-datatable">
            <DataTablePagination
              title="Tickets Raised"
              pageName="support"
              columns={columns}
              apiUrl="/admin/support-ticket-list"
              queryObj={initialValues}
            />
          </div>
        </div>
      </div>
      {showTicketModal && (
        <ModalPopup
          className="create-ticket-modal"
          show={showTicketModal}
          onHide={handleCloseModal}
          title="Raise Ticket"
        >
          <SupportTicket onHide={handleCloseModal} />
        </ModalPopup>
      )}
    </div>
  );
};

export default Support;

// <div className="col">
//           <div className="row">

//             <div className="col-md-8 col-lg-10 col-xs-12 text-left">
//               <h6 style={{ fontSize: '0.8em' }}>Dilip Thakur:</h6>
//               <p><u style={{ fontSize: '0.7em' }}>+91 1234567890</u></p>
//             </div>
//           </div>
//         </div>
//         <div className="col">
//           <div className="row">

//             <div className="col-md-8 col-lg-10 col-xs-12 text-left">
//               <h6 style={{ fontSize: '0.8em' }}>Dilip Thakur:</h6>
//               <div className="row">
//              <div>
//              <p className="mt-2"><IoCallOutline /></p>
//              </div>
//               <p><u style={{ fontSize: '0.7em' }}>+91 1234567890</u></p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col">
//           <div className="row">

//             <div className="col-md-8 col-lg-10 col-xs-12 text-left">
//               <h6 style={{ fontSize: '0.8em' }}>Dilip Thakur:</h6>
//               <p><u style={{ fontSize: '0.7em' }}>+91 1234567890</u></p>
//             </div>
//           </div>
//         </div>

{
  /* <div className="child-div">
        <p className="mb-0 custom-lable" style={{ color: 'white' }}>Dilip Thakur</p>
          <div className="child-div-2">
            <IoCallOutline className="mb-0" style={{ fontSize: '1.2em' }}/>
            <p className="mb-0" style={{ fontSize: '0.7em', paddingLeft: '5px' }}><u>+91 1234567890</u></p>
          </div>
        </div> */
}
